// @ts-nocheck
import React, {useMemo} from 'react';
import {MenuItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import Dropdown from '../../../components/common/Dropdown';
import {useGetFormsOfPaymentQuery} from '../../../store/apis/ccs';

const PaymentOptionSelectorField = ({label, name, onChange, value, error, helperText, ...props}) => {

  const {data = [], error: formsOfPaymentError, isLoading} = useGetFormsOfPaymentQuery();

  const paymentOptions = useMemo(() => {
    return [...new Set(data.map(o => o.code))];
  }, [data]);

  return (
    <>
      <RTKQueryFeedback error={formsOfPaymentError}/>
      <Dropdown
        name={name}
        label={label}
        error={error}
        value={paymentOptions.length ? value : ''}
        onChange={onChange}
        helperText={helperText}
        fullWidth
        select
        {...props}
      >
        {isLoading && <MenuItem disabled>{t`Loading...`}</MenuItem>}
        {paymentOptions.map((c, idx) => (
          <MenuItem key={idx} value={c}>{c}</MenuItem>
        ))}
      </Dropdown>
    </>
  );
};

PaymentOptionSelectorField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  hiddenLabel: PropTypes.bool,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.node,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(['medium', 'small']),
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  autoOpen: PropTypes.bool,
};

PaymentOptionSelectorField.defaultProps = {
  label: 'Payment option',
  onChange: () => null,
  value: null,
  error: false,
  helperText: '',
  autoOpen: false,
};

export default PaymentOptionSelectorField;
