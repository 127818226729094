import React from 'react';
import {Typography} from '@material-ui/core';
import {t} from 'ttag';
import TotalRefund from '../common/views/TotalRefund';
import {useSnapshot} from 'valtio';
import formState from '../common/store/form-state';
import {useWatch} from 'react-hook-form';

const Step2Label = () => {

  const {step} = useSnapshot(formState);

  if (step > 0) {
    return (
      <Typography variant={'caption'}>
        <b><Step2LabelDetails/> {t`Refund subjects`}</b> <i> <TotalRefund/> </i>
      </Typography>
    );
  }
  
  return <b>{t`Refund`}</b>;
};

export const Step2LabelDetails = () => {
  const refundSubjects = useWatch({name: 'refundSubjects'});

  return <i>{refundSubjects?.length || ''}</i>;
}

export default Step2Label;
