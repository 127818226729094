import {t} from 'ttag';
import {DataGrid} from '@mui/x-data-grid';
import {useMediaQuery} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Box, CardContent, Divider, Stack, TablePagination, Theme, Typography} from '@mui/material';
import {TransportFacilitiesYearsSelector} from '../../../components/transport-facilities-years-selector';
import {createDatetime, DATETIME_FORMAT, nowTz} from '../../../../utils/datetime/datetime-utils';
import RowsPerPageSelector from '../../../components/rows-per-page-selector/RowsPerPageSelector';
import PersonalInformation from '../../../components/personal-information/PersonalInformation';
import EmployeeSelector from '../../../components/employee-selector/EmployeeSelector';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {IAuthorization} from '../../../api/transportFacilityEndpoints.types';
import PatternSearch from '../../../components/pattern-search/PatternSearch';
import {TransportFacilitiesConsume} from './transport-facilities-consume';
import {TransportFacilitiesCancel} from "./transport-facilities-cancel";
import {TransportFacilitiesRefund} from "./transport-facilities-refund";
import RequestsListMobile from '../requests-list/RequestsListMobile';
import {columns} from '../requests-list/helpers/requestsListHelper';
import {useGetAuthorizationsQuery} from '../../../api';

export const TransportFacilitiesHistory = () => {
   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const currentYear = createDatetime(nowTz(undefined, DATETIME_FORMAT)).month >= 10 ? (Number(createDatetime(nowTz(undefined, DATETIME_FORMAT)).toFormat('yyyy')) + 1).toString() : createDatetime(nowTz(undefined, DATETIME_FORMAT)).toFormat('yyyy');
  const minYear = createDatetime(nowTz(undefined, DATETIME_FORMAT)).minus({year: 10}).toFormat('yyyy');

  const [employee, setEmployee] = useState(
    sessionStorage.getItem('selectedEmployeeId')
      ? JSON.parse(sessionStorage.getItem('selectedEmployeeId') || '{}')
      : (null as null | {employeeId: string; name: string})
  );
  const [year, setYear] = useState(createDatetime(nowTz(undefined, DATETIME_FORMAT)).toFormat('yyyy'));
  const [page, setPage] = useState(0);
  const [pattern, setPattern] = useState('');
  const [hasIntentionToConsume, setHasIntentionToConsume] = useState(undefined as undefined | IAuthorization);
  const [hasIntentionToCancel, setHasIntentionToCancel] = useState(undefined as undefined | IAuthorization);
  const [hasIntentionToRefund, setHasIntentionToRefund] = useState(undefined as undefined | IAuthorization);

  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {data, error, isLoading} = useGetAuthorizationsQuery({
    size: rowsPerPage,
    page,
    searchPattern: pattern,
    year,
    employeeId: employee?.employeeId || ''
  });

  useEffect(() => {
    if (data) {
      setTotalRowsCount(data.totalElements);
    }
  }, [data]);

  const onClickConsume = (params) => {
    setHasIntentionToConsume(params);
  };

  const onClickCancel = (params) => {
    setHasIntentionToCancel(params);
  };

  const onClickRefund = (params) => {
    setHasIntentionToRefund(params);
  };

  return (
    <>
      <Box
        sx={{backgroundColor: '#002857'}}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        p={2.5}
        columnGap={2.5}
      >
        <Typography
          color={'white'}
          variant={'body2'}
          fontFamily={'NeoSansProBold'}
        >{t`View employee:`}</Typography>
        <Box width={'45%'}>
          <EmployeeSelector
            value={employee}
            onChange={(e) => {
              setEmployee(e);
              sessionStorage.setItem('selectedEmployeeId', JSON.stringify(e));
            }}
            includesDisabled={true}
          />
        </Box>
      </Box>
      <Divider />
      <Box p={5}>{employee?.employeeId && <PersonalInformation employeeId={employee?.employeeId} />}</Box>
      <Divider />
      {isMobile ? (
        <Stack direction={'column'} p={2} spacing={2}>
          <RowsPerPageSelector
            sx={{width: '100%'}}
            value={rowsPerPage}
            totalRowsCount={totalRowsCount}
            onChange={setRowsPerPage}
          />
          <TransportFacilitiesYearsSelector
            value={year}
            onchange={(value) => setYear(value)}
            minYear={minYear}
            maxYear={currentYear}
          />
          <PatternSearch placeholder={t`Search employee...`} onChange={setPattern} />
        </Stack>
      ) : (
        <Box display={'flex'} justifyContent={'space-between'} p={2} columnGap={2}>
          <RowsPerPageSelector value={rowsPerPage} totalRowsCount={totalRowsCount} onChange={setRowsPerPage} />
          <Box display={'flex'} columnGap={2}>
            <Box sx={{width: '12.5rem'}}>
              <TransportFacilitiesYearsSelector
                value={year}
                onchange={(value) => setYear(value)}
                minYear={minYear}
                maxYear={currentYear}
              />
            </Box>
            <Box sx={{width: '16.5rem'}}>
              <PatternSearch placeholder={t`Search...`} onChange={setPattern} />
            </Box>
          </Box>
        </Box>
      )}
      <Divider />
      <CardContent>
        {isMobile ? (
          <>
            <RequestsListMobile data={data} onClickConsume={onClickConsume} onClickCancel={onClickCancel} onClickRefund={onClickRefund} />
            <TablePagination
              component='div'
              sx={{width: '100%'}}
              count={data?.totalElements || 0}
              rowsPerPage={page}
              page={page}
              onPageChange={(e, page) => setPage(page)}
              rowsPerPageOptions={[]}
            />
          </>
        ) : (
          <DataGrid
            page={page}
            loading={isLoading}
            pageSize={rowsPerPage}
            rows={data?.content || []}
            paginationMode={'server'}
            rowCount={data?.totalElements || 0}
            getRowId={(row: any) => {
              return row.tableUniqueId;
            }}
            onPageChange={(newPage) => setPage(newPage)}
            columns={columns({onClickConsume, onClickCancel, onClickRefund})}
            getRowClassName={(params: any) => (params.id % 2 === 0 ? 'even' : 'odd')}
            sx={styles.table}
            disableExtendRowFullWidth
            disableSelectionOnClick
            disableColumnSelector
            autoPageSize
            autoHeight
            pagination
          />
        )}
      </CardContent>
      {hasIntentionToConsume && (
        <TransportFacilitiesConsume
          data={hasIntentionToConsume}
          fullScreen={isMobile}
          onClose={() => setHasIntentionToConsume(undefined)}
        />
      )}
      {hasIntentionToCancel && (
        <TransportFacilitiesCancel
          data={hasIntentionToCancel}
          fullScreen={isMobile}
          onClose={() => setHasIntentionToCancel(undefined)}
        />
      )}
      {hasIntentionToRefund && (
        <TransportFacilitiesRefund
          data={hasIntentionToRefund}
          fullScreen={isMobile}
          onClose={() => setHasIntentionToRefund(undefined)}
        />
      )}
      <RTKQueryFeedback error={employee && error} />
    </>
  );
};

export default TransportFacilitiesHistory;

const styles = {
  table: {
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      lineHeight: '1rem',
      whiteSpace: 'normal'
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid #0e2846',
      height: '55px',
      minHeight: '55px !important',
      fontFamily: 'NeoSansProMedium',
      maxHeight: '55px !important'
    },
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important'
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important'
    },
    '& .odd': {
      backgroundColor: 'background.default'
    }
  }
};
