import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Typography } from '@material-ui/core';
import { t } from 'ttag';
import { BreadCrumbs, Page, SimpleCard } from 'sata-mui-react-components';
import TotalRefund from './common/views/TotalRefund';
import { resetToDefaultState } from './common/store/form-state';
import CreateRefundFormProvider from './common/CreateRefundFormProvider';
import FormStepper from './common/stepper/FormStepper';

const CreateRefundPage = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    resetToDefaultState();
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }
  return (
    <CreateRefundFormProvider>
      <Page
        breadcrumbs={
          <BreadCrumbs
            list={[
              { name: 'Home', link: '/' },
              { name: 'Refunds', link: '/refunds' },
              { name: 'Create' }
            ]}
          />
        }
      >
        <SimpleCard>
          <CardHeader
            title={<Typography variant={'h2'}>{t`Refund create`}</Typography>}
            subheader={
              <Typography>
                <TotalRefund />
              </Typography>
            }
          />
          <FormStepper />
        </SimpleCard>
      </Page>
    </CreateRefundFormProvider>
  );
};

CreateRefundPage.propTypes = {
  refund: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

CreateRefundPage.defaultProps = {
  refund: null,
  open: true,
  onClose: null
};

export default CreateRefundPage;
