// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardActions,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core';
import { t } from 'ttag';
import { BreadCrumbs, Page, SimpleCard } from 'sata-mui-react-components';
import {
  AttachmentsRefundView,
  CommentsRefundView,
  HistoryRefundView,
  InfoRefundView,
  MaskRefundView,
  ReceiptsRefundView,
  SubjectsRefundView
} from './index';

import Status from '../../../../components/status';
import { useGetRefundQuery } from '../../../../store/apis/ccs';
import CloseRefund from './view-refund/refund-actions/CloseRefund';
import CancelRefund from './view-refund/refund-actions/CancelRefund';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const ViewRefundPage = ({ match }) => {
  const { refundId } = match.params;
  const { data, error, isLoading } = useGetRefundQuery(refundId);
  const [recipient] = data?.recipients || [];
  const isProcessed = data?.state === 'PROCESSED';
  const isCanceled = data?.state === 'CANCELED';

  return (
    <Page
      breadcrumbs={
        <BreadCrumbs
          list={[
            { name: 'Home', link: '/' },
            { name: 'Refunds', link: '/refunds' },
            { name: refundId }
          ]}
        />
      }
    >
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Refund`}</Typography>}
          action={
            <Box sx={{ display: 'flex' }}>
              <Status state={data?.state} />
            </Box>
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box py={2}>
              <InfoRefundView refund={data} isLoading={isLoading} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Box py={2}>
              <SubjectsRefundView
                refundSubjects={data?.refundSubjects || []}
                currency={data?.currency}
                isLoading={isLoading}
              />
            </Box>
            <Box py={2}>
              <ReceiptsRefundView recipient={recipient} isLoading={isLoading} />
            </Box>
            <Box py={2}>
              <MaskRefundView refund={data} isLoading={isLoading} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box py={2}>
              <AttachmentsRefundView
                state={data?.state}
                attachments={data?.attachments}
                refundId={refundId}
                isLoading={isLoading}
              />
            </Box>
            <Box py={2}>
              <CommentsRefundView refundId={refundId} />
            </Box>
            <Box py={2}>
              <HistoryRefundView refundId={refundId} />
            </Box>
          </Grid>
        </Grid>
        <CardActions>
          {!isCanceled && <CancelRefund refundId={refundId} />}
          {!isProcessed && <CloseRefund refundId={refundId} />}
        </CardActions>
      </SimpleCard>
      <RTKQueryFeedback error={error} />
    </Page>
  );
};

ViewRefundPage.propTypes = {
  refund: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ViewRefundPage.defaultProps = {
  refund: null,
  open: true,
  onClose: null,
  comments: [],
  attachments: [],
  history: []
};

export default ViewRefundPage;
