// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {Box, TextField} from '@material-ui/core';
import validationSchema from './validationSchema';
import {Add} from '@material-ui/icons';
import {Button} from 'sata-mui-react-components';
import {useGetRefundSubjectMutation} from '../../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../../components/common/RTKQueryFeedback';
import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

const RefundSubjectImportForm = ({refundSubjects, refundType, officeCode, onImport}) => {

  const {enqueueSnackbar} = useSnackbar()
  const [getRefundSubject, {error, isLoading}] = useGetRefundSubjectMutation();
  const {control, handleSubmit} = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      refundType,
      officeCode,
      companyCode: '',
      documentNumber: ''
    }
  });

  const importHandler = (values) => {
    if (refundSubjects.some(r => r.documentNumber === values.documentNumber)) {
      return enqueueSnackbar(t`Refund subject already in list.`, {variant: 'warning'})
    }
    getRefundSubject(values).then(({data}) => data && onImport(data));
  }

  return (
    <Box display={'flex'} justifyContent={'space-between'} py={2}>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
      <Box maxWidth={'100px'}>
        <Controller
          control={control}
          name={'companyCode'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Code`}
              name={'companyCode'}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              placeholder={'ex. 331'}
              size={'small'}
              required
              fullWidth
            />
          )}/>
      </Box>
      <Box flexGrow={1} px={1}>
        <Controller
          control={control}
          name={'documentNumber'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Document number`}
              name={'documentNumber'}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              placeholder={'ex. 24001732'}
              size={'small'}
              required
              fullWidth
            />
          )}/>
      </Box>
      <Box>
        <Button onClick={handleSubmit(importHandler)} size={'medium'} endIcon={<Add/>}>
          {t`Add`}
        </Button>
      </Box>
    </Box>
  );
};

RefundSubjectImportForm.propTypes = {
  refundSubjects: PropTypes.array,
  refundType: PropTypes.string,
  officeCode: PropTypes.string,
  onImport: PropTypes.func,
};

RefundSubjectImportForm.defaultProps = {
  refundSubjects: [],
  refundType: null,
  officeCode: null,
  onImport: () => null,
};

export default RefundSubjectImportForm;
