import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Chip} from '@material-ui/core';
import {Cancel, Done, Help, Update} from '@material-ui/icons';

export const STATUSES = {
  PENDING: {
    name: 'PENDING',
    icon: <Update fontSize='small' style={{color: '#FFF'}} />,
    color: '#f57c00'
  },
  CANCELED: {
    name: 'CANCELED',
    icon: <Cancel fontSize='small' style={{color: '#FFF'}} />,
    color: '#d32f2f'
  },
  PROCESSED: {
    name: 'PROCESSED',
    icon: <Done fontSize='small' style={{color: '#FFF'}} />,
    color: '#388e3c'
  },
  UNKNOWN: {
    name: 'UNKNOWN',
    icon: <Help fontSize='small' style={{color: '#FFF'}} />,
    color: '#1976d2'
  }
};

const Status = ({ state, variant, fullwidth, isMobile }) => {
  const status = useMemo(() => {
    if (STATUSES.hasOwnProperty(state?.toUpperCase())) {
      return STATUSES[state?.toUpperCase()];
    }
    return STATUSES.UNKNOWN;
  }, [state]);

  return (
    <Chip
      avatar={status.icon}
      variant={variant}
      size="small"
      label={!isMobile && status.name}
      style={{ width: fullwidth ? '100%' : 'auto', backgroundColor: status.color, color: 'white' }}
      disabled
    />
  );
};

Status.propTypes = {
  state: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'outlined']),
  fullwidth: PropTypes.bool,
  isMobile: PropTypes.bool
};

Status.defaultProps = {
  state: null,
  variant: 'filled',
  fullwidth: false,
  isMobile: false
};

export default Status;
