import * as Yup from 'yup';
import {t} from 'ttag';

export const issueOfficeValidationSchema = Yup.object().shape({
  description: Yup.string()
    .required(t`Required field.`)
    .matches(/^[A-Z0-9]{9}$/, t`ex. PDLS408AA`),
  active: Yup.boolean()
    .required(t`Required field.`)
});
