// @ts-nocheck
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {t} from 'ttag';
import {useWatch} from 'react-hook-form';
import Amount from './Amount';

const RefundSubjectTaxes = () => {

  const taxes = useWatch({name: 'taxes'});

  if (!taxes) return null;

  const field = s => <Typography noWrap variant={'caption'} component={'div'}>{s}</Typography>;

  return (
    <Card>
      <CardHeader title={t`Taxes`}/>
      <CardContent>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{t`Code`}</TableCell>
                <TableCell align={'right'}>{t`Value`}</TableCell>
                <TableCell align={'right'}>{t`Current value`}</TableCell>
                <TableCell align={'right'}>{t`Type`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/*{[...taxes.filter(tx => tx.type !== 'paidTaxes')].sort((t1, t2) => t1.type.localeCompare(t2.type)).map((c, idx) => (*/}
              {[...taxes].sort((t1, t2) => t1.type.localeCompare(t2.type)).map((c, idx) => (
                <TableRow key={idx}>
                  <TableCell component={'th'} scope={'row'}>{field(c.taxCode)}</TableCell>
                  <TableCell align={'right'}>{field(<Amount value={c.value} currency={c.currency}/>)}</TableCell>
                  <TableCell align={'right'}>{field(<Amount value={c.currentValue} currency={c.currency}/>)}</TableCell>
                  <TableCell align={'right'}>{field(c.type)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default RefundSubjectTaxes;
