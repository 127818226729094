import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { Help, Done, Update, Cancel } from '@material-ui/icons';

export const STATUSES = {
  PENDING: {
    name: 'PENDING',
    icon: <Update fontSize='small' />,
    severity: 'warning'
  },
  CANCELED: {
    name: 'CANCELED',
    icon: <Cancel fontSize='small' />,
    severity: 'error'
  },
  PROCESSED: {
    name: 'PROCESSED',
    icon: <Done fontSize='small' />,
    severity: 'success'
  },
  UNKNOWN: {
    name: 'UNKNOWN',
    icon: <Help fontSize='small' />,
    severity: 'info'
  }
};

const Status = ({ state, variant, fullwidth }) => {
  const status = useMemo(() => {
    if (STATUSES.hasOwnProperty(state?.toUpperCase())) {
      return STATUSES[state?.toUpperCase()];
    }
    return STATUSES.UNKNOWN;
  }, [state]);

  return (
    <Alert
      icon={status.icon}
      variant={variant}
      severity={status.severity}
      style={{ width: fullwidth ? '100%' : 'auto' }}
    >
      {status.name}
    </Alert>
  );
};

Status.propTypes = {
  state: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'standard', 'outlined']),
  fullwidth: PropTypes.bool
};

Status.defaultProps = {
  state: null,
  variant: 'filled',
  fullwidth: false
};

export default Status;
