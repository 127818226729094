// @ts-nocheck
import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import {Theme} from '@mui/material/styles';
import {Circle, ExpandLess, ExpandMore, Home} from '@mui/icons-material';
// import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme} from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
// import downloadFile from '../../public/cash_and_miles_calculator.xlsx';
// import {actions, useStore} from '';
const file = '../../public/cash_and_miles_calculator.xlsx';
interface subLinksTitleInterface {
  title: string;
  subLinks: subLinksInterface[];
}

interface subLinksInterface {
  path: string;
  text: string;
  externalLink?: boolean;
}

interface SidebarDropDownLinkInterface {
  path: string;
  icon: any;
  text: string;
  itemKey?: number;
  exact: boolean;
  subLinks: subLinksTitleInterface[] | subLinksInterface[];
}

export const SidebarDropDownLink = ({path, icon, text, itemKey, exact, subLinks}: SidebarDropDownLinkInterface) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [subLinkOpen, setSubLinkOpen] = useState(false);
  // const {app} = useStore();
  const history = useHistory();
  const {pathname} = useLocation();
  // const matches = useMatch({path, end: exact});
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const clickHandler = (path, externalLink = false) => {
    !externalLink ? history.push(path) : window.open(path, '_blank');
    if (!isDesktop) {
      setOpen(false);
    }
  };

  const handleClick = () => {
    setOpen(true);
    if (!subLinkOpen) {
      setSubLinkOpen(path);
    }
    if (subLinkOpen) {
      setSubLinkOpen(subLinkOpen !== path ? path : null);
    }
  };

  // @ts-ignore
  return (
    <Box>
      <ListItemButton key={itemKey} onClick={handleClick}>
        <ListItemIcon sx={styles.icon} children={icon} />
        <ListItemText primary={text} />
        {path === subLinkOpen && open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        sx={{backgroundColor: '#011E42'}}
        in={path === subLinkOpen && open}
        timeout='auto'
        unmountOnExit
      >
        {'title' in subLinks[0] ? (
          <>
            {subLinks?.map((subLink, idx) => (
              <List key={idx} component='div' disablePadding sx={{mb: 2}}>
                <ListItemText
                  secondaryTypographyProps={{pl: 2.5, pt: 1, fontSize: '12px', textTransform: 'uppercase'}}
                  secondary={subLink?.title}
                />
                {subLink?.subLinks.map((e, idx) => (
                  <ListItemButton key={idx} onClick={() => clickHandler(e.path, e.externalLink)}>
                    <ListItemIcon sx={{pl: 1, minWidth: '2em'}}>
                      <Circle sx={styles.circle(pathname.includes(e.path))} />
                    </ListItemIcon>
                    <ListItemText
                      secondaryTypographyProps={{sx: styles.subLink(pathname.includes(e.path))}}
                      secondary={e.text}
                    />
                  </ListItemButton>
                ))}
              </List>
            ))}
          </>
        ) : (
          <List component='div' disablePadding>
            {subLinks?.map((e, idx) => (
              <ListItemButton key={idx} onClick={() => clickHandler(e.path, e.externalLink)}>
                <ListItemIcon sx={{pl: 1, minWidth: '2em'}}>
                  <Circle sx={styles.circle(pathname.includes(e.path))} />
                </ListItemIcon>
                <ListItemText
                  secondaryTypographyProps={{sx: styles.subLink(pathname.includes(e.path))}}
                  secondary={e.text}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </Collapse>
    </Box>
  );
};

const styles = {
  icon: {
    color: (theme: Theme) => theme.palette.secondary.contrastText,
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: (theme: Theme) => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  activeButton: {
    backgroundColor: (theme: Theme) => theme.palette.secondary.light,
    borderLeftWidth: '0.250rem',
    borderStyle: 'solid',
    borderLeftColor: (theme: Theme) => theme.palette.primary.main,
    paddingLeft: (theme) => theme.spacing(1) * 1.5,
    '&:hover': {
      backgroundColor: (theme: Theme) => '#011E42'
    }
  },
  subLink: (match) => ({
    color: (theme: Theme) => (match ? theme.palette.primary.main : theme.palette.secondary.contrastText)
  }),
  circle: (match) => ({
    color: (theme: Theme) => (match ? theme.palette.primary.main : 'rgba(255,255,255,0.2)'),
    fontSize: '7px'
  })
};

SidebarDropDownLink.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
  itemKey: PropTypes.any,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.node,
      externalLink: PropTypes.bool
    })
  )
};

SidebarDropDownLink.defaultProps = {
  exact: false,
  path: '/',
  icon: Home,
  text: 'Link',
  subLinks: []
};

export default SidebarDropDownLink;
