const typography = {
  fontFamily: ["NeoSansProRegular", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  h1: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: "2.18rem",
    letterSpacing: "-0.3px"
  },
  h2: {
    fontSize: "1.875rem",
    letterSpacing: "-0.3px"
  },
  h3: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: "1.375rem",
    letterSpacing: "-0.06px"
  },
  h4: {
    fontSize: "1.125rem",
    letterSpacing: "-0.06px"
  },
  h5: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: "1rem",
    letterSpacing: "-0.05px"
  },
  h6: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: "0.875rem",
    letterSpacing: "-0.05px"
  },
  overline: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
  },
  subtitle2: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
  },
  button: {
    fontFamily: ["NeoSansProMedium", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
  }
};

export default typography;
