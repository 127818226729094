// @ts-nocheck
import axios from 'axios';

import { msalInstance } from './msal-instance';
import {API_ENDPOINT, API_MOCK, MSA_SCOPES_CCS} from './settings';
import {retrieveCurrentTokenPerScope} from "sata-auth";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await retrieveCurrentTokenPerScope(msalInstance, MSA_SCOPES_CCS || '');
    } catch (e) {
      // token = await msalInstance.acquireTokenRedirect({ scopes });
    }
    config.headers = {
      Authorization: `Bearer ${token}`
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };

export const mockRoutes = [
  {method: 'GET', path: '/users', response: {data: []}},
  {method: 'GET', path: '/roles', response: {data: []}},
  {method: 'GET', path: '/offices', response: {data: []}},
  {method: 'GET', path: '/forms-of-payment', response: {data: []}},
  {method: 'GET', path: '/issue-offices', response: {data: [{issueOfficeId: 1, description: 'PDLS408AA', active: true}]}},
];

export const mockResolver = ({method = 'GET', url}) => {
  const route = mockRoutes.find(r => r.method.toUpperCase() === method && url.includes(r.path));
  if (!route) throw new Error('Not found');
  return {data: route.response};
}

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => {
  return async ({ url = '', method, body: data, destructFn = (d) => d.data, ...rest }) => {
    try {
      if (API_MOCK) return {data: destructFn(mockResolver({method, url}))};
      const result = await axiosInstance({ url: baseUrl + url, method, data, ...rest });
      return { data: destructFn(result) };
    } catch ({ response, message }) {
      return {
        error: { status: response?.status, data: response?.data, message }
      };
    }
  };
};
