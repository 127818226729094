import React from 'react';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import LabeledDetail from '../../../../../components/labeled-detail';

const RecipientRefundView = ({ recipient, isLoading }) => {
  const {
    name,
    contact,
    email,
    identificationNumber,
    documentType,
    documentId,
    postalCode,
    address,
    iban
  } = recipient;
  return (
    <Card >
      <CardHeader title={t`Recipient`} />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Name`}
              children={name}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Contact`}
              children={contact}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Email`}
              children={email}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`NIF`}
              children={identificationNumber}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Document Type`}
              children={documentType}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Document ID`}
              children={documentId}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Postal Code`}
              children={postalCode}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Address`}
              children={address}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`IBAN`}
              children={iban}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

RecipientRefundView.propTypes = {
  recipient: PropTypes.shape({
    name: PropTypes.string,
    contact: PropTypes.string,
    email: PropTypes.string,
    identificationNumber: PropTypes.string,
    documentType: PropTypes.string,
    documentId: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
    iban: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool
};

RecipientRefundView.defaultProps = {
  recipient: {
    name: '',
    contact: '',
    email: '',
    identificationNumber: '',
    documentType: '',
    documentId: '',
    postalCode: '',
    address: '',
    iban: ''
  },
  isLoading: false
};

export default RecipientRefundView;
