import * as Yup from 'yup';
import {t} from 'ttag';

export const officeValidationSchema = Yup.object().shape({
  officeCode: Yup.string()
    .required(t`Required field.`)
    .matches(/^[A-Z]{3}[A-Z0-9]{6}$/, t`ex. PDLS40151`),
  currencyCode: Yup.string()
    .required(t`Required field.`)
    .matches(/^[A-Z0-9]{3}$/, t`ex. EUR`),
  companyCode: Yup.string()
    .matches(/^[A-Z][A-Z0-9]$/, t`ex. S4`),
  ticketCode: Yup.string()
    .matches(/^[0-9]{3}$/, t`ex. 331`),
  description: Yup.string(),
});
