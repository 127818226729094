// @ts-nocheck
import * as Yup from 'yup';
import {t} from 'ttag';
import {validationSchema} from '../step-2/refund-subjects/crud-forms/create-edit/validationSchema';

export const createRefundValidationSchema = Yup.object().shape({
  step1: Yup.object().shape({
    refundType: Yup.string()
      .nullable()
      .required(t`Required field.`),
    office: Yup.object().nullable().shape({
      officeCode: Yup.string()
        .nullable()
        .required(t`Required field.`)
        .matches(/^[A-Z]{3}[A-Z0-9]{6}$/, t`ex. PDLS40151`),
      currencyCode: Yup.string()
        .nullable()
        .required(t`Required field.`)
        .matches(/^[A-Z]{3}$/, t`ex. EUR`),
    }).required(t`Required field.`),
    reason: Yup.string()
      .nullable()
  }),
  step2: Yup.object().shape({
    refundSubjects: Yup.array()
      .of(validationSchema)
      .min(1, t`Add at least one refund subject.`),
    totalRefund: Yup.number()
      .nullable(),
    modified: Yup.boolean()
      .nullable(),
  }),
  step3: Yup.object().shape({
    recipient: Yup.object().shape({
      name: Yup.string()
        .nullable()
        .required(t`Required field.`),
      email: Yup.string()
        .nullable()
        .required(t`Required field.`)
        .email(t`Invalid email.`),
      documentType: Yup.string()
        .nullable()
        .matches(/^(Passport|Citizen Card)$/, t`Passport or Citizen Card`),
      documentId: Yup.string()
        .nullable(),
      nif: Yup.string()
        .nullable()
        .matches(/^\d{9}$/, t`123456789`),
      contact: Yup.string()
        .nullable()
        .matches(/^([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,}|)$/, t`123456789`),
      address: Yup.string()
        .nullable(),
      postalCode: Yup.string()
        .nullable(),
      iban: Yup.string()
        .nullable()
        .when(['$refundSubjects'], (refundSubjects = [], schema) =>
          // refundSubjects :: read from context that's why the $ shit
          refundSubjects.some(rs => rs.paymentOptions.some(po => po.indicator === 'CAT')) ? Yup.string().required(t`Required field.`) : schema
        )
    }),
  }),
});

export default createRefundValidationSchema;
