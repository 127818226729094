import React from 'react';
import { useSataAuth } from 'sata-auth';
import { Typography } from '@material-ui/core';
import { Page, BreadCrumbs } from 'sata-mui-react-components';

const Dashboard = () => {
  const { account } = useSataAuth();

  const breadcrumbs = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'Dashboard'
    }
  ];

  return (
    <Page breadcrumbs={<BreadCrumbs list={breadcrumbs} />}>
      <Typography variant='h2'>Welcome, {account.name}</Typography>
    </Page>
  );
};

export default Dashboard;
