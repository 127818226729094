import * as Yup from 'yup';
import {t} from 'ttag';

const validationSchema = Yup.object().shape({
  refundType: Yup.string()
    .nullable()
    .required(t`Required field.`),
  officeCode: Yup.string()
    .nullable()
    .required(t`Required field.`),
  companyCode: Yup.string()
    .nullable()
    .required(t`Required field.`)
    .matches(/^[0-9]{3}$/, t`ex. 331`),
  documentNumber: Yup.string()
    .nullable()
    .required(t`Required field.`)
    .matches(/^[0-9]{10}$/, t`ex. 2403343305`),
});

export default validationSchema;
