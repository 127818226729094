const white = "#fff";

const palette = {
  common: {
    black: "rgba(56, 56, 56, 1)",
    white: white
  },
  background: {
    paper: "#fff",
    default: "#F9FAFC"
  },
  primary: {
    light: "rgba(88, 176, 237, 1)",
    main: "rgba(0, 129, 186, 1)",
    dark: "rgba(0, 85, 138, 1)",
    contrastText: "#fff"
  },
  secondary: {
    light: "rgba(58, 80, 132, 1)",
    main: "rgba(0, 40, 87, 1)",
    dark: "rgba(0, 0, 46, 1)",
    contrastText: "#fff"
  },
  error: {
    light: "rgba(255, 100, 80, 1)",
    main: "rgba(203, 47, 38, 1)",
    dark: "rgba(146, 0, 0, 1)",
    contrastText: "#fff"
  },
  info: {
    light: "rgba(88, 176, 237, 1)",
    main: "rgba(0, 129, 186, 1)",
    dark: "rgba(0, 85, 138, 1)",
    contrastText: "#fff"
  },
  success: {
    light: "#77cf72",
    main: "#459d45",
    dark: "#026e18",
    contrastText: "#fff"
  },
  warning: {
    light: "#ffc947",
    main: "#ee9801",
    dark: "#b66a00",
    contrastText: "#fff"
  },
  text: {
    primary: "rgba(0, 40, 87, 1)",
    secondary: "rgba(98, 98, 98, 1)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)"
  },
  table: {
    odd: "rgba(0, 0, 0, 0.04)",
    cell:{
      fontSize : '1rem',
      color: "rgba(98, 98, 98, 1)"
    }
  },
  textField: {
    border: "rgba(0, 0, 0, 0.23)"
  }
};

export default palette;
