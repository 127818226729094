import * as Yup from 'yup';
import {t} from 'ttag';

export const formOfPaymentValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(t`Required field.`),
  code: Yup.string()
    .required(t`Required field.`)
    .matches(/^[A-Z]{2,3}$/, t`ex. CAT`),
  amadeusCode: Yup.string()
    .required(t`Required field.`)
    .matches(/^[A-Z0-9]{2}$/, t`ex. CA`),
});
