import {IconButton} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import {t} from 'ttag';
import {Box} from "@mui/material";

export const columns = ({editHandler, removeHandler}) => [
  {
    field: 'name',
    headerName: t`Name`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'code',
    headerName: t`Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'amadeusCode',
    headerName: t`Amadeus code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => editHandler(params.row)} size={'small'} aria-label='edit' color='primary'>
            <Edit fontSize='inherit'/>
          </IconButton>
          <IconButton onClick={() => removeHandler(params.row)} size={'small'} aria-label='delete'>
            <Delete fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];

export const helper = {
  columns
};

export default helper;
