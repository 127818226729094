import React from 'react';
import {useFormState} from 'react-hook-form';
import ErrorsFeedback from '../../../../../components/common/ErrorsFeedback';

const ErrorsFeedbackConnected = () => {
  const { errors } = useFormState();

  return <ErrorsFeedback errors={errors} hideTitle/>;
};

export default ErrorsFeedbackConnected;
