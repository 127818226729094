// @ts-nocheck
import React, {useEffect, useState} from 'react';
import { useSataAuth } from 'sata-auth';
import {useResidentValidateMutation} from "../../store/apis/ccs";
import {Card, Typography, CardHeader, CardContent, CardActions, Button, TextField} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStyles from './AzoresResidentValidatorWidgetStyles';
import {validateNIFs} from "./utils";
import RTKQueryFeedback from "../../components/common/RTKQueryFeedback";
import {faCheck, faPlus, faReply, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";

const ResidentValidator = () => {
  const [residentValidate, {data, error, isLoading, isSuccess}] = useResidentValidateMutation();

  const { account } = useSataAuth();

  const classes = useStyles();

  const [textInputValue, setTextInputValue] = useState('');
  const [listOfVatsToValidate, setListOfVatsToValidate] = useState([]);
  const [helperText, setHelperText] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (isSuccess) setHasSubmitted(true);
  }, [isSuccess]);

  const handleSubmitRequest = () => {
    residentValidate({
      agent: account.name,
      validations: listOfVatsToValidate.map(vat => ({taxpayerNumber: vat}))
    });
  };

  const onResetButtonClick = () => {
    setHasSubmitted(false);
    setListOfVatsToValidate([]);
  };

  const clearFile = (e, vatIdx) => {
    e.preventDefault();
    e.stopPropagation();

    listOfVatsToValidate.splice(vatIdx, 1)
    setListOfVatsToValidate([...listOfVatsToValidate]);
  };

  const onAddHandler = () => {
    let nifList = [];
    if (listOfVatsToValidate.includes(textInputValue)) return setHelperText('The introduced VAT is already added to the list');
    if ((nifList = validateNIFs(textInputValue))) {
      setListOfVatsToValidate([...new Set([...listOfVatsToValidate, ...nifList])]);
      setTextInputValue('');
      setHelperText('');
    } else {
      setHelperText('The introduced VAT is invalid');
    }
  };

  return (
    <>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={<Typography variant={'subtitle1'} className={classes.cardHeaderTitle}>{'Validation of Resident in the Azores'}</Typography>}
        />
        <CardContent>
          <Typography variant={"subtitle2"}>This tool allows you to validate if a given portuguese taxpayer number has its home address in the Azores archipelago and help you issuing the new Azores Fare.</Typography>
          <TextField
            label={'VAT'}
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value.replace(',,', ','))}
            onKeyPress={e => e.key === 'Enter' && onAddHandler()}
            helperText={helperText}
            error={Boolean(helperText)}
            disabled={hasSubmitted || isLoading}
            fullWidth
          />
          {!isLoading &&
            <div className={classes.vatListContainer}>
              {!hasSubmitted
                ?
                listOfVatsToValidate?.map((vat, idx) => (
                  <div key={idx} className={classes.vatWrapper}>
                    <FontAwesomeIcon className={classes.deleteVatIcon} icon={faTrash} style={{width: '18px', height: '18px'}} onClick={e => clearFile(e, idx)}/>&nbsp;&nbsp;
                    <b className={classes.bold}>{vat}</b>
                    <br/>
                  </div>
                ))
                : data?.validations?.map((validation, idx) => (
                  <div key={idx} className={classes.vatWrapper}>
                    {validation.valid === true
                      ? <FontAwesomeIcon className={classes.validVatIcon} icon={faCheck} style={{width: '18px', height: '18px'}}/>
                      : <FontAwesomeIcon className={classes.invalidVatIcon} icon={faTimes} style={{width: '18px', height: '18px'}}/>
                    }
                    &nbsp;&nbsp;<b className={classes.bold}>{validation.taxpayerNumber}</b>
                    &nbsp;<span className={classes.vatObservation}>{(validation.observations !== '') && (validation.observations !== null) ? '(' + validation.observations + ')' : ''}</span>
                    <br/>
                  </div>
                ))
              }
            </div>
          }
        </CardContent>
        <CardActions>
          {!hasSubmitted
            ?
            <div className={classes.buttonsContainer}>
              <Button variant={'outlined'} color={"secondary"} onClick={onAddHandler} disabled={isLoading}><FontAwesomeIcon icon={faPlus}/>&nbsp;{'Add'}</Button>
              <Button variant={'outlined'} color={"primary"} onClick={handleSubmitRequest} disabled={isLoading || listOfVatsToValidate.length <= 0}><FontAwesomeIcon icon={faCheck}/>&nbsp;{'Validate'}</Button>
            </div>
            :
            <div className={classes.buttonsContainer}>
              <Button variant={'outlined'} onClick={onResetButtonClick}><FontAwesomeIcon icon={faReply}/>&nbsp;{'Reset'}</Button>
            </div>
          }
        </CardActions>
      </Card>
    </>
  );
};

export default ResidentValidator;
