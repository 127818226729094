// @ts-nocheck
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {BasicTable, SearchBar} from 'sata-mui-react-components';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {useGetUsersQuery} from '../../../store/apis/ccs';
import {columns} from './employeesListHelper';

const EmployeesList = ({ removeHandler, editHandler }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterPattern, setFilterPattern] = useState(null);
  const { data, error, isLoading } = useGetUsersQuery({page, pattern: filterPattern});

  return (
    <>
      <RTKQueryFeedback error={error} />
      <SearchBar
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setFilterPattern(e.target.value);
        }}
      />
      <BasicTable
        pagination
        page={(page - 1)}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode={'server'}
        getRowId={(row) => row.userId}
        loading={isLoading}
        columns={columns({ editHandler, removeHandler })}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

EmployeesList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

EmployeesList.defaultProps = {};

export default EmployeesList;
