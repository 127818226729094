export const giveRowAUniqueId = (tableData) => {
  if (tableData?.content) {
    const newLine = tableData?.content;
    newLine.forEach((item, i) => {
      item.tableUniqueId = i + 1;
    });
    return {...tableData, newLine};
  }

  const newLine = tableData;
  newLine.transportFacilitiesPerBeneficiaries?.forEach((item, i) => {
    item.tableUniqueId = i + 1;
  });
  return newLine;
};
