import {t} from 'ttag';
import React from 'react';
import {Check} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useCancelAuthorizationMutation} from '../../../../api';
import {IAuthorization} from '../../../../api/transportFacilityEndpoints.types';
import RTKQueryFeedback from '../../../../../components/common/RTKQueryFeedback';

export const TransportFacilitiesCancel = ({data, onClose, fullScreen}) => {
  const [onCancel, {data: dataCancel, error, isLoading}] = useCancelAuthorizationMutation();

  const handleSave = () => {
    onCancel(data).then((e: {data?: IAuthorization; error?: any}) => !e?.error && onClose());
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} onClose={onClose} open>
        <DialogTitle id='responsive-dialog-title'>{'Cancel'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{t`Do you really want to cancel this Transport Facility?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} color={'inherit'} onClick={onClose} autoFocus>
            {t`Cancel`}
          </Button>
          <Button
            startIcon={<Check />}
            onClick={handleSave}
            variant={'contained'}
            color={'primary'}
            autoFocus
          >{t`Comfirm`}</Button>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        data={dataCancel}
        error={error}
        isLoading={isLoading}
        successMessage={t`Transport Facility canceled with success!`}
      />
    </>
  );
};

export default TransportFacilitiesCancel;
