// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {AlertDialogModal, Button} from 'sata-mui-react-components';
import {useDeleteRefundMutation} from '../../../store/apis/ccs';
import {Box, DialogActions, DialogContent} from '@material-ui/core';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';

const DeleteRefundDialog = ({refund, open, onClose, ...rest}) => {

  const [deleteRefund, {data, error, isLoading, isSuccess}] = useDeleteRefundMutation();

  return (
    <AlertDialogModal title={t`Refund`} variant={'error'} open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} isSuccess={isSuccess}/>
      <DialogContent dividers>
        <Box p={1}>
          {t`Do you really want to delete this item?`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={1}>
          <Button variant={'contained'} type={'error'} onClick={() => deleteRefund(refund).then(r => !r.error && onClose())}>{t`Delete`}</Button>
        </Box>
        <Box mx={1}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
      </DialogActions>
    </AlertDialogModal>
  );
};

DeleteRefundDialog.propTypes = {
  refund: PropTypes.shape({
    refundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteRefundDialog.defaultProps = {
  refund: null,
  open: true,
  onClose: null,
};

export default DeleteRefundDialog;
