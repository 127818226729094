import React, {useEffect, useMemo} from 'react';
import {Card, CardHeader} from '@material-ui/core';
import {calculateTotalRefundSubject} from '../../../common/helpers';
import {useFormContext, useWatch} from 'react-hook-form';
import {t} from 'ttag';
import Amount from './Amount';

const RefundSubjectTotal = () => {

  const {setValue} = useFormContext();
  const [currencyCode, amounts, taxes] = useWatch({name: ['currencyCode', 'amounts', 'taxes']});

  const totalRefund = useMemo(() => {
    return calculateTotalRefundSubject({amounts, taxes});
  }, [amounts, taxes]);

  useEffect(() => {
    setValue('totalRefund', parseFloat(totalRefund), {shouldValidate: true});
  }, [totalRefund, setValue]);

  return <Amount value={totalRefund} currency={currencyCode}/>;
};

export const RefundSubjectTotalCard = () => {
  return (
    <Card>
      <CardHeader title={t`Total refund`} subheader={<RefundSubjectTotal/>}/>
    </Card>
  );
}

export default RefundSubjectTotal;
