// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import { Button } from 'sata-mui-react-components';
import { useCancelRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';

const CancelRefund = ({ refundId }) => {
  const [cancelRefund, { data, error, isLoading }] =
    useCancelRefundMutation();

  return (
    <>
      <Button
        type='error'
        variant='contained'
        style={{ marginRight: 5 }}
        onClick={() => cancelRefund(refundId)}
      >{t`CANCEL`}</Button>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

CancelRefund.propTypes = {};

CancelRefund.defaultProps = {};

export default CancelRefund;
