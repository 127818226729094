// @ts-nocheck
import React, {useState} from 'react';
import {BreadCrumbs, Button, Page, SimpleCard} from 'sata-mui-react-components';
import {CardHeader, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {Add} from '@material-ui/icons';
import RefundsList from './RefundsList';
import DeleteRefundDialog from './refund-forms/DeleteRefundDialog';
import {useHistory} from 'react-router';

const Refunds = () => {

  const history = useHistory();

  const [refundToDelete, setRefundToDelete] = useState(null);

  return (
    <Page breadcrumbs={<BreadCrumbs list={[{name: 'Home', link: '/'}, {name: 'Refunds'}]}/>}>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Refunds`}</Typography>}
          action={<Button onClick={() => history.push('refunds/create')} startIcon={<Add/>}>{t`NEW`}</Button>}
        />
        <RefundsList
          viewHandler={({refundId}) => history.push(`refunds/${refundId}`)}
          removeHandler={setRefundToDelete}
        />
        {refundToDelete && (
          <DeleteRefundDialog
            refund={refundToDelete}
            onClose={() => setRefundToDelete(null)}
          />
        )}
      </SimpleCard>
    </Page>
  );
};

export default Refunds;

