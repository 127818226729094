import React from 'react';
import {t} from 'ttag';
import {AlertDialogModal, Button} from 'sata-mui-react-components';
import {DialogActions, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";

const DeleteRefundSubjectDialog = ({onDelete, open, onClose, ...rest}) => {

  return (
    <AlertDialogModal title={t`Refund subject`} variant={'error'} open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
      <DialogContent dividers>
        <Box p={1}>
          {t`Do you really want to delete this item?`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={1}>
          <Button variant={'contained'} type={'error'} onClick={() => {
            onDelete();
            onClose();
          }}>{t`Delete`}</Button>
        </Box>
        <Box mx={1}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
      </DialogActions>
    </AlertDialogModal>
  );
};

DeleteRefundSubjectDialog.propTypes = {
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteRefundSubjectDialog.defaultProps = {
  onDelete: () => null,
  open: true,
  onClose: null,
};

export default DeleteRefundSubjectDialog;
