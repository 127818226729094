import {t} from 'ttag';
import * as React from 'react';
import {DateTime} from 'ts-luxon';
import {
  Box,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import {createDatetime as dt} from '../../../../utils/datetime/datetime-utils';
import {MoreVert} from "@mui/icons-material";

export const RequestsListMobile = ({data, onClickConsume, onClickCancel, onClickRefund}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (data?.content.length === 0) {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center' mt={3} mb={1.75}>
        <Typography
          variant={'body2'}
          color={'secondary'}
          fontFamily={'NeoSansProRegular'}
        >{t`Not possible to found authorizations`}</Typography>
      </Stack>
    );
  }

  return (
    <Grid container>
      {data?.content.map((row, idx) => {
        return (
          <Paper key={idx} sx={{width: '100%', mb: 2}} elevation={0}>
            <CardHeader
              sx={{bgcolor: '#002857', py: 1}}
              title={<Typography color={'white'}>{row?.familyMember?.name}</Typography>}
              action={<><IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{color: 'white'}}
              >
                <MoreVert />
              </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {row?.state === 'PENDING' && (
                    <>
                      <MenuItem onClick={() => onClickConsume(row)}>{t`Consume`}</MenuItem>
                      <MenuItem onClick={() => onClickCancel(row)}>{`Cancel`}</MenuItem>
                    </>
                  )
                  }
                  {row?.state === 'ISSUED' && (
                    <>
                    <MenuItem onClick={() => onClickConsume(row)}>{t`Consume`}</MenuItem>
                    <MenuItem onClick={() => onClickRefund(row)}>{`Refund`}</MenuItem>
                    </>
                    )
                  }
                </Menu>
              </>}
            />
            <List sx={{padding: 0}} dense>
              <ListItem>
                <ListItemText primary={<Typography sx={styles.title}>{t`Relationship`}</Typography>} />
                <ListItemText
                  primary={
                    <Typography textAlign={'end'} textTransform={'capitalize'}>
                      {row?.beneficiary?.relationshipType?.toLowerCase()}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={styles.odd}>
                <ListItemText primary={<Typography sx={styles.title}>{t`National ID Card`}</Typography>} />
                <ListItemText primary={<Typography textAlign={'end'}>{row?.familyMember?.idNumber}</Typography>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Typography sx={styles.title}>{t`Industry Discount`}</Typography>} />
                <ListItemText
                  primary={
                    <Typography textAlign={'end'}>{row?.allowanceDefinition?.facilityType?.designation}</Typography>
                  }
                />
              </ListItem>
              <ListItem sx={styles.odd}>
                <ListItemText primary={<Typography sx={styles.title}>{t`Trip start`}</Typography>} />
                <ListItemText
                  primary={
                    <Typography textAlign={'end'}>
                      {dt(row?.request?.travelDate).toLocaleString(DateTime.DATE_MED)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Typography sx={styles.title}>{t`Traveling company`}</Typography>} />
                <ListItemText
                  primary={
                    <Typography textAlign={'end'}>
                      {row.allowanceDefinition?.travelInCompanies?.map((e) => e?.acronym || e?.code).join(', ')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={styles.odd}>
                <ListItemText primary={<Typography sx={styles.title}>{t`Autorização`}</Typography>} />
                <ListItemText primary={<Typography textAlign={'end'}>{row.number}</Typography>} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Typography sx={styles.title}>{t`Reservation`}</Typography>} />
                <ListItemText
                  primary={
                    <Box display='flex' justifyContent='flex-end'>
                      <Chip
                        sx={{
                          backgroundColor: '#7F93AB',
                          color: 'white',
                          width: '4.5rem',
                          pt: 0.5,
                          textTransform: 'capitalize'
                        }}
                        size='small'
                        label={row.state?.toLowerCase()}
                      />
                    </Box>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        );
      })}
    </Grid>
  );
};

const styles = {
  title: {
    fontFamily: 'NeoSansProMedium',
    fontSize: '0.875rem'
  },
  odd: {
    backgroundColor: 'background.default'
  }
};

export default RequestsListMobile;
