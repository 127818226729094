// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import RoleForm from './RoleForm';
import { roleValidationSchema } from './helpers';
import { useCreateRoleMutation } from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const AddRoleDialog = ({ role, open, onClose }) => {
  const [addRole, { data, error, isLoading }] = useCreateRoleMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: role,
    validationSchema: roleValidationSchema,
    onSubmit: (values) => addRole(values).then((r) => !r.error && onClose())
  });

  return (
    <>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Role`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <RoleForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddRoleDialog.propTypes = {
  role: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddRoleDialog.defaultProps = {
  open: true
};

export default AddRoleDialog;
