// @ts-nocheck
import React from 'react';
import {Box} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const ScriptPreviewSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'black'
        }}
      >
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
        <Skeleton animation='wave' />
      </Box>
    </>
  );
};

ScriptPreviewSkeleton.propTypes = {};

ScriptPreviewSkeleton.defaultProps = {};

export default ScriptPreviewSkeleton;
