// @ts-nocheck
import React from 'react';
import RefundSubjectFormDialog from './RefundSubjectFormDialog';
import {t} from 'ttag';
import {useSnapshot} from 'valtio';
import formState, {setFormData, setRefundSubjectToEdit, setRefundSubjectToImport} from '../../../../common/store/form-state';
import {useFormContext, useWatch} from 'react-hook-form';
import {calculateRefundSubjects} from '../common/helpers'
import RefundSubjectFormDialogProvider from './RefundSubjectFormDialogProvider';

const RefundSubjectFormDialogConnected = ({editable}) => {

  const {setValue} = useFormContext();
  const {refundSubjectToImport, refundSubjectToEdit} = useSnapshot(formState);
  const [refundSubjects, office] = useWatch({name: ['refundSubjects', 'office']});

  if (!refundSubjectToImport && !refundSubjectToEdit) return null;

  /** @param {RefundSubject} rs */
  const addHandler = rs => {
    const updatedRefund = calculateRefundSubjects([...refundSubjects, rs]);
    setValue('refundSubjects', [...updatedRefund.refundSubjects], {shouldValidate: true});
    setValue('modified', updatedRefund.modified, {shouldValidate: true});
    setValue('totalRefund', updatedRefund.totalRefund, {shouldValidate: true});
    setFormData({...updatedRefund});
  }

  /** @param {RefundSubject} rs */
  const editHandler = rs => {
    const updatedRefund = calculateRefundSubjects(refundSubjects.map(r => r.documentNumber === rs.documentNumber ? rs : r));
    setValue('refundSubjects', [...updatedRefund.refundSubjects], {shouldValidate: true});
    setValue('modified', updatedRefund.modified, {shouldValidate: true});
    setValue('totalRefund', updatedRefund.totalRefund, {shouldValidate: true});
    setFormData({...updatedRefund});
  }

  if (refundSubjectToImport) {
    return (
      <RefundSubjectFormDialogProvider refundSubject={refundSubjectToImport} currencyCode={office?.currencyCode}>
        <RefundSubjectFormDialog
          actionTitle={t`Import`}
          editable={editable}
          onClose={() => setRefundSubjectToImport(null)}
          onSubmit={rs => {
            setRefundSubjectToImport(null);
            addHandler(rs);
          }}
        />
      </RefundSubjectFormDialogProvider>
    );
  }

  if (refundSubjectToEdit) {
    return (
      <RefundSubjectFormDialogProvider refundSubject={refundSubjectToEdit} currencyCode={office?.currencyCode}>
        <RefundSubjectFormDialog
          actionTitle={t`Save`}
          editable={editable}
          onClose={() => setRefundSubjectToEdit(null)}
          onSubmit={rs => {
            setRefundSubjectToEdit(null);
            editHandler(rs);
          }}
        />
      </RefundSubjectFormDialogProvider>
    );
  }

  return null;
};

export default RefundSubjectFormDialogConnected;
