// @ts-nocheck
import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, makeStyles, TextField } from '@material-ui/core';

import { useGetAllRolesQuery } from '../../store/apis/ccs';
import RTKQueryFeedback from '../common/RTKQueryFeedback';

const RolesSearch = ({ value, onChange }) => {
  const { data, error, isLoading } = useGetAllRolesQuery();
  const classes = useStyles();
  const [input, setInput] = useState('');

  const onDelete = (roleId) => () => {
    onChange(value.filter((v) => v.roleId !== roleId));
  };

  const handleInputChange = (e, value) => {
    setInput(value);
  };
  return (
    <div className={classes.root}>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
      <Autocomplete
        multiple
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={data?.data || []}
        inputValue={input}
        renderTags={() => null}
        getOptionLabel={(opt) => `${opt.name}`}
        onInputChange={handleInputChange}
        onChange={(_, roles) => onChange(roles)}
        getOptionSelected={(option, value) => value.roleId === option.roleId}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' placeholder={t`Roles`} />
        )}
      />
      <div className={classes.valueContainer}>
        {value.map((v) => (
          <Chip key={v.roleId} label={v.name} onDelete={onDelete(v.roleId)} />
        ))}
      </div>
    </div>
  );
};

RolesSearch.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

RolesSearch.defaultProps = {
  value: []
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  },
  valueContainer: {
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default RolesSearch;
