import {t} from 'ttag';
import {IconButton} from '@material-ui/core';
import {Delete, Launch} from '@material-ui/icons';
import StatusChip from '../../components/status/StatusChip';
import {Box} from "@mui/material";

const { DateTime } = require('luxon');

export const columns = ({ viewHandler, removeHandler }) => [
  {
    field: 'processId',
    headerName: t`Process ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'documentNumber',
    headerName: t`Refund ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'agent',
    headerName: t`Agent`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'createdOn',
    headerName: t`Processed On`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'state',
    headerName: t`Status`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      return <StatusChip state={params?.row?.state} variant='default' />;
    }
  },
  {
    field: 'total',
    type: 'number',
    headerName: t`Total`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2 / 3,
    valueGetter: (params) => {
      return `${params?.row?.total.toFixed(2)} ${params?.row?.currency}`;
    }
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const diff = DateTime.now().diff(
        DateTime.fromFormat(params.row.createdOn, 'yyyy-MM-dd HH:mm:ss'),
        ['days', 'hours']
      );
      const { days, hours } = diff;
      return (
        <Box>
          <IconButton
            onClick={() => viewHandler(params.row)}
            size={'small'}
            aria-label='edit'
            color='primary'
          >
            <Launch fontSize='inherit' />
          </IconButton>
          {days === 0 && hours < 24 && (
            <IconButton
              onClick={() => removeHandler(params.row)}
              size={'small'}
              aria-label='delete'
            >
              <Delete fontSize='inherit' />
            </IconButton>
          )}
        </Box>
      );
    }
  }
];
export const columnsMobile = ({ viewHandler, removeHandler }) => [
  {
    field: 'processId',
    headerName: t`Process ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'documentNumber',
    headerName: t`Refund ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2
  },
  {
    field: 'state',
    headerName: t`Status`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      return <StatusChip state={params?.row?.state} variant='default' isMobile={true}/>;
    }
  },
  {
    field: 'total',
    type: 'number',
    headerName: t`Total`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (params) => {
      return `${params?.row?.total.toFixed(2)} ${params?.row?.currency}`;
    }
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const diff = DateTime.now().diff(
        DateTime.fromFormat(params.row.createdOn, 'yyyy-MM-dd HH:mm:ss'),
        ['days', 'hours']
      );
      const { days, hours } = diff;
      return (
        <Box>
          <IconButton
            onClick={() => viewHandler(params.row)}
            size={'small'}
            aria-label='edit'
            color='primary'
          >
            <Launch fontSize='inherit' />
          </IconButton>
          {days === 0 && hours < 24 && (
            <IconButton
              onClick={() => removeHandler(params.row)}
              size={'small'}
              aria-label='delete'
            >
              <Delete fontSize='inherit' />
            </IconButton>
          )}
        </Box>
      );
    }
  }
];

export const helper = {
  columns,
  columnsMobile
};

export default helper;
