import {t} from "ttag";
import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {Grid, TextField, Typography} from "@mui/material";

const TransportFacilitiesConsumeForm = () => {
  const {control} = useFormContext();

  return (
    <Grid spacing={2} container>
      <Grid xs={6} item>
        <Controller
          name={'pnr'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <>
              <Typography variant={'body1'}>{t`PNR`}</Typography>
              <TextField
                {...field}
                onChange={(e) => field?.onChange(e.target.value ? e.target.value.toUpperCase() : null)}
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
              />
            </>
          )}
        />
      </Grid>
      <Grid xs={6} item>
        <Controller
          name={'ticket'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <>
              <Typography variant={'body1'}>{t`Ticket`}</Typography>
              <TextField
                {...field}
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
              />
            </>
          )}
        />
      </Grid>
      {/*<Grid xs={2} item>*/}
      {/*  <Controller*/}
      {/*    name={'isUsed'}*/}
      {/*    control={control}*/}
      {/*    defaultValue={''}*/}
      {/*    render={({field, fieldState: {error}}) => (*/}
      {/*      <>*/}
      {/*        <Typography variant={'body1'}>{t`Used?`}</Typography>*/}
      {/*        <Switch checked={field.value} onChange={field.onChange} />*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</Grid>*/}
    </Grid>
  );

};

export default TransportFacilitiesConsumeForm;
