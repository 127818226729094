// @ts-nocheck
import React, {useState} from 'react';
import {BasicTable, SearchBar} from 'sata-mui-react-components';
import {columns} from './officesListHelper';
import {useGetOfficesQuery} from '../../../store/apis/ccs';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';

const OfficesList = ({editHandler, removeHandler}) => {
  const [pattern, setPattern] = useState(null);
  const {data = [], error, isLoading, isFetching} = useGetOfficesQuery({
    pattern
  });

  const handlePatternInput = (e) => {
      setPattern(e.target.value)
  }

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <SearchBar
        value={pattern}
        onChange={handlePatternInput}
        />
      <BasicTable
        autoHeight
        getRowId={({officeId}) => officeId}
        columns={columns({editHandler, removeHandler})}
        rows={data}
        pageSize={100}
        rowsPerPageOptions={[100]}
        loading={isFetching || isLoading}
        disableSelectionOnClick
      />
    </>

  );
};

OfficesList.propTypes = {
  editHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default OfficesList;
