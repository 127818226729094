// @ts-nocheck
import { t } from 'ttag';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BasicTable, SearchBar } from 'sata-mui-react-components';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { columns, columnsMobile } from './refundsListHelper';
import { useGetRefundsQuery } from '../../store/apis/ccs';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import DateRangePicker from '../../components/date-range-picker';

import './refunds-list.scss';

const RefundsList = ({ viewHandler, removeHandler }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filterPattern, setFilterPattern] = useState(null);
  const [filterRange, setFilterRange] = useState({
    startDate: DateTime.now().minus({ weeks: 2 }).toISODate(),
    endDate: DateTime.now().plus({ days: 2 }).toISODate()
  });
  const [filterModified, setFilterModified] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data = [],
    error,
    isLoading
  } = useGetRefundsQuery({ page, filterPattern, filterRange, filterModified });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <SearchBar
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setFilterPattern(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <DateRangePicker range={filterRange} onChange={setFilterRange} />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl variant='outlined' style={{ width: '100%' }}>
            <InputLabel id='demo-simple-select-outlined-label'>{t`Modified`}</InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={filterModified}
              onChange={(e) => setFilterModified(e.target.value)}
              label={t`Modified`}
              defaultValue={false}
            >
              <MenuItem value={false}>{t`All`}</MenuItem>
              <MenuItem value={'1'}>{t`True`}</MenuItem>
              <MenuItem value={'0'}>{t`False`}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <BasicTable
        autoHeight
        pagination
        autoPageSize
        page={page - 1}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode='server'
        getRowId={(row) => row.refundId}
        columns={
          isMobile
            ? columnsMobile({ viewHandler, removeHandler })
            : columns({ viewHandler, removeHandler })
        }
        rows={data?.data || []}
        loading={isLoading}
        onCellClick={({ row, field }) => {
          if (field !== 'actions') {
            viewHandler(row);
          }
        }}
        disableSelectionOnClick
        disableExtendRowFullWidth={true}
        pageSize={data?.pagination?.rowsPerPage || 5}
      />
      <RTKQueryFeedback error={error} />
    </>
  );
};

RefundsList.propTypes = {
  viewHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired
};

export default RefundsList;
