import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {API_ENDPOINT_TRANSPORT} from "../../utils/settings";
import {Employee} from "./userEndpoints.types";

export const type = 'user';

export const userEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getCurrentUserFacilities: builder.query<any, void>({
    query: () => ({
      url: `${API_ENDPOINT_TRANSPORT}/users/me`,
      method: 'GET',
    }),
    transformResponse: (data) => ({
      ...data?.data,
      roles: data?.data?.roles.map((r) => r.name)
    })
  }),

  getUsers: builder.query<any, any>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/users`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => data?.data,
  }),

  getCurrentSelectedUser: builder.query<Employee, {employeeId: string}>({
    query: ({employeeId}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/users/${employeeId}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
  }),
});

export default userEndpoints;
