import {IconButton} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import {t} from 'ttag';
import {Box} from "@mui/material";

export const columns = ({editHandler, removeHandler}) => [
  {
    field: 'officeCode',
    headerName: t`Office Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'companyCode',
    headerName: t`Company Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'ticketCode',
    headerName: t`Ticket Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'currencyCode',
    headerName: t`Currency Code`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'description',
    headerName: t`Description`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'active',
    headerName: t`Active`,
    type: 'boolean',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => editHandler(params.row)} size={'small'} aria-label='edit' color='primary'>
            <Edit fontSize='inherit'/>
          </IconButton>
          <IconButton onClick={() => removeHandler(params.row)} size={'small'} aria-label='delete'>
            <Delete fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];

export default columns;
