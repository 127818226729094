// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, CardHeader} from '@material-ui/core';
import {t} from 'ttag';

const AtcMask = ({mask}) => {
  return (
    <Card>
      <CardHeader title={t`Mask`}/>
      <CardContent>
        <Box sx={{backgroundColor: 'black', color: 'white', borderRadius: '5px', padding: '5px 15px', fontSize: '100%'}}>
          <pre>
            {mask}
          </pre>
        </Box>
      </CardContent>
    </Card>
  );
};

AtcMask.propTypes = {
  mask: PropTypes.string,
};

export default AtcMask;
