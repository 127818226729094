import {t} from 'ttag';
import React from 'react';
import {TextField, Tooltip, Typography} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {DeleteOutlined} from "@mui/icons-material";
import {Controller} from "react-hook-form";
import BeneficiaryFacilityTypeSelector from '../../../../components/beneficiary-facility-type-selector/BeneficiaryFacilityTypeSelector';
import palette from "../../../../../theme/palette";

export const columns = ({updateHandler, updateCompanion, deleteHandler, control}) => [
  {
    flex: 1,
    minWidth: 200,
    field: 'beneficiaryFamilyMember.familyMember.name',
    headerName: t`Nome`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => row.new ?
        <Controller
            name={'name'}
            control={control}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                    value={value}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                      updateCompanion(row);
                    }}
                    size={'small'}
                    color={'primary'}
                    error={Boolean(error)}
                />
            )}
        />
        : <Tooltip title={row.beneficiaryFamilyMember.familyMember.name}><Typography sx={{textTransform: 'capitalize'}} variant={'body2'} color={palette.secondary.main}>{row.beneficiaryFamilyMember.familyMember.name.toLowerCase()}</Typography></Tooltip>
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'beneficiaryFamilyMember.beneficiary.name',
    headerName: t`Beneficiário`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => row.beneficiaryFamilyMember?.beneficiary?.name ? <Tooltip title={row.beneficiaryFamilyMember.beneficiary.name}><Typography variant={'body2'} color={palette.secondary.main}>{row.beneficiaryFamilyMember.beneficiary.name}</Typography></Tooltip> :
        <Typography variant={'body2'} color='secondary'>{row.new || row.new === null ? t`Acompanhante`: t`Sem correspondência`}</Typography>
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'beneficiaryFamilyMember.familyMember.idNumber',
    headerName: t`Número de Identificação`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => row.new ?
        <Controller
            name={'idNumber'}
            control={control}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <TextField
                    value={value}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e: any) => {
                      onChange(e.target.value);
                      updateCompanion(row);
                    }}
                    size={'small'}
                    color={'primary'}
                    error={Boolean(error)}
                />
            )}
        />
        : <Typography variant={'body2'} color={palette.secondary.main}>{row.beneficiaryFamilyMember.familyMember.idNumber}</Typography>
  },
  {
    flex: 1,
    minWidth: 200,
    field: 'allowanceDefinitionUsage.allowanceDefinition.facilityType.id',
    headerName: t`Tipo de Facilidade`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => <BeneficiaryFacilityTypeSelector row={row} onChange={updateHandler}/>
  },
  {
    type: 'actions',
    field: 'actions',
    headerName: t`Actions`,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({row}) => [
      row.new && <GridActionsCellItem key={0} icon={<DeleteOutlined sx={{color: '#00285780'}}/>} onClick={() => deleteHandler(row)} label='Delete'/>,
    ]
  }
];

export const helper = {
  columns
};

export default helper;
