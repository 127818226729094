import React from 'react';
import AtcMask from './AtcMask';
import {useSnapshot} from 'valtio';
import formState from '../../../../../../../common/store/form-state';
import {useWatch} from 'react-hook-form';

const AtcMaskConnected = () => {
  const {atcMaskVisible} = useSnapshot(formState);
  const atcMask = useWatch({name: 'atcMask'});

  if (atcMaskVisible) {
    return <AtcMask mask={atcMask}/>;
  }

  return null;
};

export default AtcMaskConnected;
