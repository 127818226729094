// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {Button} from 'sata-mui-react-components';
import {useFormik} from 'formik';
import FormOfPaymentForm from './FormOfPaymentForm';
import {formOfPaymentValidationSchema} from './helpers';
import {useEditFormOfPaymentMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const EditFormOfPaymentFormDialog = ({formOfPayment, open, onClose, ...rest}) => {

  const [editFormOfPayment, {data, error, isLoading}] = useEditFormOfPaymentMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formOfPayment,
    validationSchema: formOfPaymentValidationSchema,
    onSubmit: values => editFormOfPayment(values).then(r => !r.error && onClose()),
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogTitle>
        <Typography variant={'h2'} component={'div'}>
          {t`Form of payment`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1}>
          <FormOfPaymentForm formik={formik}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={.4}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit}>{t`Save`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditFormOfPaymentFormDialog.propTypes = {
  formOfPayment: PropTypes.shape({
    formOfPaymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

EditFormOfPaymentFormDialog.defaultProps = {
  formOfPayment: null,
  open: true,
  onClose: null,
};

export default EditFormOfPaymentFormDialog;
