// @ts-nocheck
import {t} from 'ttag';
import React from 'react';
import {Button} from 'sata-mui-react-components';

import {useCloseRefundMutation} from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';

const CloseRefund = ({ refundId }) => {
  const [closeRefund, { data, error, isLoading }] = useCloseRefundMutation();

  return (
    <>
      <Button
        type='success'
        variant='contained'
        style={{ marginLeft: 5 }}
        onClick={() => {
          closeRefund(refundId);
        }}
      >{t`PROCESS`}</Button>
      <RTKQueryFeedback data={data} isLoading={isLoading} error={error} />
    </>
  );
};

CloseRefund.propTypes = {};

CloseRefund.defaultProps = {};

export default CloseRefund;
