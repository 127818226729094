import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './refundSubjectAmountsColumns';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import {useFormContext, useWatch} from 'react-hook-form';

const RefundSubjectAmounts = ({editable}) => {

  const {setValue} = useFormContext();
  const [amounts, amountsOriginal] = useWatch({name: ['amounts', 'original.amounts']});

  const editHandler = ({id, field, value}) => {
    const original = amountsOriginal.find(({localId}) => localId === id);
    const modified = Boolean(!original || original?.[field] !== value);
    setValue('amounts', amounts.map(tx => tx.localId === id ? {...tx, [field]: value, modified} : tx), {shouldValidate: true});
  }

  return (
    <Card>
      <CardHeader title={t`Amounts`}/>
      <CardContent>
        <DataGrid
          autoHeight
          density={'compact'}
          onCellEditCommit={editHandler}
          getRowId={({localId}) => localId}
          columns={columns({editable})}
          rows={amounts?.filter(a => ['totalFareUsed', 'totalCancellationFee'].includes(a.type)) || []}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          hideFooter
        />
      </CardContent>
    </Card>
  );
};

RefundSubjectAmounts.propTypes = {
  editable: PropTypes.bool,
};

RefundSubjectAmounts.defaultProps = {
  editable: true,
};

export default RefundSubjectAmounts;
