export class AxiosError extends Error {
  private status: any;
  private data: any;
  constructor(message, status, data) {
    super(Boolean(data?.description) ? data?.description : data || message);
    this.status = status;
    this.data = data;
  }
}

export default AxiosError;
