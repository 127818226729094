import React from 'react';
import {ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {Box} from "@mui/material";

const StatusSkeleton = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40}/>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Skeleton/>
            <Box sx={classes.caption} color='textPrimary'>
              <Skeleton/>
            </Box>
          </>
        }
        secondary={
          <React.Fragment>
            <Skeleton/>
            <Skeleton/>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

const classes = {
  caption: {
    display: 'inline',
    opacity: 0.5,
    fontSize: '.7em',
    fontStyle: 'italic'
  }
};

StatusSkeleton.propTypes = {};

StatusSkeleton.defaultProps = {};

export default StatusSkeleton;
