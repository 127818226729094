import React from 'react';
import {useWatch} from 'react-hook-form';
import RefundSubjectsList from './RefundSubjectsList';

const RefundSubjectsListConnected = () => {
  const refundSubjects = useWatch({name: 'refundSubjects'});

  return <RefundSubjectsList refundSubjects={refundSubjects}/>;
};

export default RefundSubjectsListConnected;
