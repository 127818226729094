// @ts-nocheck
export const type = 'office';
export const endpoints = (builder) => ({
  // Get offices list
  getOffices: builder.query({
    query: (params) => ({ url: `/offices`, params, destructFn: (d) => d?.data?.data }),
    providesTags: ( data = [] ) => {
      return data
        .map(({ officeId }) => ({ type, id: officeId }))
        .concat({ type, id: 'list' });
    }
  }),

  // Create office
  createOffice: builder.mutation({
    query: (office) => ({
      url: '/offices',
      method: 'POST',
      body: office
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  // Edit office
  editOffice: builder.mutation({
    query: (office) => ({
      url: `/offices/${office.officeId}`,
      method: 'PATCH',
      body: office
    }),
    invalidatesTags: (result, error, { officeId }) => [
      { type, officeId }
    ]
  }),

  // Delete office
  deleteOffice: builder.mutation({
    query: (office) => ({
      url: `/offices/${office.officeId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  })
});

export default endpoints;
