import {t} from 'ttag';
import * as Yup from 'yup';

export const transportFacilityValidationSchema = Yup.object().shape({
    colaborator: Yup.object().required(t`Este campo é obrigatório`),
    travelDate: Yup.string().required(t`Este campo é obrigatório`),
    observations: Yup.string().nullable(),
    travelCompany: Yup.string().required(t`Must be defined`),
    motive: Yup.string().required(t`Este campo é obrigatório`),
    authorizations: Yup.array().min(1, t`Must have atleast one authorization`).required(t`Este campo é obrigatório`)
});
