// @ts-nocheck
/**
 * Updates refund subject with modified flag.
 *
 * @param {RefundSubject} rs
 * @return {RefundSubject}
 */
export const updateRefundSubjectModifiedFlag = (rs) => {
  return {...rs, modified: [...rs.amounts, ...rs.taxes, ...rs.paymentOptions].some(value => value?.modified)};
};

/**
 * Updates refund subject total.
 *
 * @param {RefundSubject} rs
 * @return {RefundSubject}
 */
export const updateRefundSubjectTotal = (rs) => {
  const totalRefund = parseFloat(calculateTotalRefundSubject(rs));
  return {...rs, amounts: rs.amounts.map(a => a.type === 'totalRefund' ? {...a, currentValue: totalRefund} : a), totalRefund};
};

/**
 * Updates refund subject total and modified flag.
 *
 * @param {RefundSubject} rs
 * @return {RefundSubject}
 */
export const updateRefundSubject = (rs) => {
  return updateRefundSubjectModifiedFlag(updateRefundSubjectTotal(rs));
};

/**
 * Calculates refund subject total.
 * @param {Amount[]} amounts
 * @param {Tax[]} taxes
 * @returns {string}
 */
export const calculateTotalRefundSubject = ({ amounts = [], taxes = []}) => {
  const totalFarePaid = amounts.find((a) => a.type === 'totalFarePaid')?.currentValue || 0;
  const totalFareUsed = amounts.find((a) => a.type === 'totalFareUsed')?.currentValue || 0;
  const totalCancellationFee = amounts.find((a) => a.type === 'totalCancellationFee')?.currentValue || 0;
  const totalRefundableTaxes = taxes.filter((tx) => tx.type === 'refundableTaxes').reduce((acc, curr) => acc + curr.currentValue, 0);
  return (totalFarePaid - totalFareUsed - totalCancellationFee + totalRefundableTaxes).toFixed(2);
};

/**
 * Checks modified flags | Calculate total refund | Return the updated refund subjects list.
 *
 * @param {RefundSubject[]} refundSubjects
 * @return {{refundSubjects: RefundSubject[], totalRefund: number, modified: boolean}}
 */
export const calculateRefundSubjects = (refundSubjects = []) => {
  let modified = false;
  let totalRefund = 0;
  const updatedRefundSubjectsList = refundSubjects.map(rs => {
    const updatedRefundSubject = updateRefundSubject(rs);
    if (updatedRefundSubject.modified) modified = true;
    totalRefund += parseFloat(rs.totalRefund);
    return updatedRefundSubject;
  });
  return {
    refundSubjects: updatedRefundSubjectsList,
    totalRefund: totalRefund,
    modified: modified
  };
};
