import Amount from '../../step-2/refund-subjects/crud-forms/create-edit/form/views/Amount';
import {useWatch} from 'react-hook-form';

const TotalRefund = () => {
  const [refundSubjects, totalRefund, office] = useWatch({name: ['refundSubjects', 'totalRefund', 'office']});

  if (!refundSubjects?.length) return null;

  return <Amount value={totalRefund} currency={office?.currencyCode}/>;
};

export default TotalRefund;
