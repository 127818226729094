// @ts-nocheck
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {createRefundValidationSchema} from './validationSchema';
import {useSnapshot} from 'valtio';
import formState, {clone} from './store/form-state';

const schemas = Object.values(createRefundValidationSchema.fields);

const CreateRefundFormProvider = ({children}) => {
  const {step, form} = useSnapshot(formState);
  const defaultState = clone(form);

  const methods = useForm({
    resolver: yupResolver(schemas[step]),
    defaultValues: defaultState,
    context: defaultState
  });

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  );
};

export default CreateRefundFormProvider;
