// @ts-nocheck
import { t } from 'ttag';
import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';

import { usePrintRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';

const PrintRefund = ({ refundId }) => {
  const [generatePDf, { data, error, isLoading }] = usePrintRefundMutation();

  useMemo(() => {
    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }, [data]);

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        style={{ marginLeft: 5 }}
        onClick={() => {
          generatePDf(refundId);
        }}
      >{t`PRINT`}</Button>
      <RTKQueryFeedback data={data} isLoading={isLoading} error={error} />
    </>
  );
};

PrintRefund.propTypes = {};

PrintRefund.defaultProps = {};

export default PrintRefund;
