// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import EmployeeForm from './EmployeeForm';
import { employeeValidationSchema } from './helpers';
import {
  useEditUserMutation,
  useGetUserQuery
} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const EditEmployeeDialog = ({ employee: { employeeId }, open, onClose }) => {
  const {
    data: employee,
    error: employeeError,
    isLoading: employeeIsLoading
  } = useGetUserQuery(employeeId);
  const [editUser, { data, error, isLoading }] = useEditUserMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: employee,
    validationSchema: employeeValidationSchema,
    onSubmit: (values) => {
      editUser({
        ...values,
        roles: values.roles.map((e) => e.name)
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Employees`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <EmployeeForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        error={error || employeeError}
        isLoading={isLoading || employeeIsLoading}
        data={data}
      />
    </>
  );
};

EditEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditEmployeeDialog.defaultProps = {
  open: true
};

export default EditEmployeeDialog;
