// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import {SataAuthProvider} from 'sata-auth';

import reportWebVitals from './reportWebVitals';
import {msalInstance, scopes} from './utils';

import App from './App';

ReactDOM.render(
  <SataAuthProvider instance={msalInstance} listsOfScopes={scopes}>
    <App />
  </SataAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
