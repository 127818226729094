// @ts-nocheck
import {Box, IconButton} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {t} from 'ttag';
import PaymentOptionSelectorField from '../../../../../../../../../common/PaymentOptionSelectorField';
import Amount from '../Amount';

export const columns = ({removeHandler, editable, disableDeleteBtn = false}) => [
  {
    field: 'indicator',
    headerName: t`Type`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderEditCell: ({value, id, field, api}) => (
      <PaymentOptionSelectorField
        label={''}
        value={value}
        size={'small'}
        onChange={(e) => {
          api.setEditCellValue({id, field, value: e.target.value});
          api.commitCellChange({id, field}, e);
        }}
        fullWidth
        autoOpen
      />
    ),
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => <Amount value={row.value} currency={row.currency}/>
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => <Amount value={row.currentValue} currency={row.currency}/>
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    hide: !editable,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton onClick={() => removeHandler(params.row)} disabled={disableDeleteBtn} size={'small'} aria-label='delete'>
            <Delete fontSize='inherit'/>
          </IconButton>
        </Box>
      )
    }
  },
];
