import React from 'react';
import {Step, StepContent, StepLabel, Stepper} from '@material-ui/core';
import {AccountBox, ConfirmationNumber, Description} from '@material-ui/icons';
import Step1Label from '../../step-1/Step1Label';
import Step2Label from '../../step-2/Step2Label';
import Step1Form from '../../step-1/Step1Form';
import Step2Form from '../../step-2/Step2Form';
import Step3Form from '../../step-3/Step3Form';
import {t} from 'ttag';
import {useSnapshot} from 'valtio';
import formState from '../store/form-state';

const FormStepper = () => {
  const {step} = useSnapshot(formState);

  return (
    <Stepper activeStep={step} orientation={'vertical'}>
      <Step>
        <StepLabel StepIconComponent={() => <Description/>}><Step1Label/></StepLabel>
        <StepContent>
          <Step1Form/>
        </StepContent>
      </Step>
      <Step>
        <StepLabel StepIconComponent={() => <ConfirmationNumber/>}><Step2Label/></StepLabel>
        <StepContent>
          <Step2Form/>
        </StepContent>
      </Step>
      <Step>
        <StepLabel StepIconComponent={() => <AccountBox/>}>{t`Recipient`}</StepLabel>
        <StepContent>
          <Step3Form/>
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default FormStepper;
