// @ts-nocheck
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import LoadingScreen from './LoadingScreen';

const RTKQueryFeedback = ({data, error, isLoading, isSuccess, successMessage, snackbarOnSuccessOptions, snackbarOnErrorOptions}) => {

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (data || isSuccess) {
      enqueueSnackbar(successMessage, snackbarOnSuccessOptions);
    }
  }, [successMessage, data, isSuccess, enqueueSnackbar, snackbarOnSuccessOptions]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.data?.description || error.message, snackbarOnErrorOptions);
    }
  }, [error, enqueueSnackbar, snackbarOnErrorOptions]);

  return isLoading ? <LoadingScreen /> : null;
};

RTKQueryFeedback.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  successMessage: PropTypes.string,
  snackbarOnErrorOptions: PropTypes.object,
  snackbarOnSuccessOptions: PropTypes.object
};

RTKQueryFeedback.defaultProps = {
  data: null,
  isLoading: false,
  isSuccess: false,
  error: null,
  successMessage: 'Success',
  snackbarOnErrorOptions: {variant: 'error'},
  snackbarOnSuccessOptions: {variant: 'success'},
};

export default RTKQueryFeedback;
