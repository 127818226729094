import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {IBeneficiariesList, IBeneficiary, IBeneficiaryDTO} from './beneficiaryEndpoints.types';
import {giveRowAUniqueId} from '../../utils/table/table-utils';
import {API_ENDPOINT_TRANSPORT} from '../../utils/settings';
import {IListParams} from './utils/utils.types';

export const type = 'beneficiaries';

export const beneficiaryEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getBeneficiaries: builder.query<IBeneficiariesList, IListParams>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/beneficiaries`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: () => [{type, id: 'list'}]
  }),

  getBeneficiary: builder.query<IBeneficiary, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/beneficiaries/${id}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data
  }),

  removeBeneficiary: builder.mutation<IBeneficiariesList, {id: string}>({
    query: ({id}) => ({
      url: `${API_ENDPOINT_TRANSPORT}/beneficiaries/${id}`,
      method: 'DELETE'
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  putBeneficiary: builder.mutation<void, IBeneficiaryDTO>({
    query: (body) => ({
      url: `${API_ENDPOINT_TRANSPORT}/beneficiaries`,
      method: 'PUT',
      body
    }),
    transformResponse: (data) => data?.data
  })
});

export default beneficiaryEndpoints;
