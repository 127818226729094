// @ts-nocheck
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';

const RefundSubjectRoutes = () => {

  const coupons = useWatch({name: 'coupons'});

  if (!coupons) return null;

  const field = s => <Typography noWrap variant={'caption'} component={'div'}>{s}</Typography>;

  return (
    <Card>
      <CardHeader title={t`Itinerary`}/>
      <CardContent>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{t`Route`}</TableCell>
                <TableCell align={'right'}>{t`Flight number`}</TableCell>
                <TableCell align={'right'}>{t`Fare basis`}</TableCell>
                <TableCell align={'right'}>{t`Coupon status`}</TableCell>
                <TableCell align={'right'}>{t`Date`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((c, idx) => (
                <TableRow key={idx}>
                  <TableCell component={'th'} scope={'row'}>{field(`${c.originAirport || ''} - ${c.destinationAirport || ''}`)}</TableCell>
                  <TableCell align={'right'}>{field(`${c.flightNumber || ''}`)}</TableCell>
                  <TableCell align={'right'}>{field(c.fareBasis)}</TableCell>
                  <TableCell align={'right'}>{field(c.couponStatus)}</TableCell>
                  <TableCell align={'right'}>{field(c.datetime)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>

  );
};

RefundSubjectRoutes.propTypes = {
  formik: PropTypes.shape({}),
};

export default RefundSubjectRoutes;
