import React from 'react';
import {Button, ButtonGroup} from '@material-ui/core';
import {SubjectOutlined, TableChartOutlined} from '@material-ui/icons';

const AtcMaskToggle = ({onChange, visible}) => {

  return (
    <ButtonGroup variant={'contained'} size={'small'} color={'default'}>
      <Button onClick={() => onChange(false)} color={!visible ? 'primary': 'inherit'}><TableChartOutlined fontSize={'small'}/></Button>
      <Button onClick={() => onChange(true)} color={visible ? 'primary': 'inherit'}><SubjectOutlined fontSize={'small'}/></Button>
    </ButtonGroup>
  );
};

export default AtcMaskToggle;
