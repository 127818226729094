// @ts-nocheck
import React, {useState} from 'react';
import {Button, SimpleCard} from 'sata-mui-react-components';
import FormsOfPaymentList from './FormsOfPaymentList';
import {Add} from '@material-ui/icons';
import {CardHeader, Typography} from '@material-ui/core';
import CreateFormOfPaymentFormDialog from './forms-of-payment-forms/CreateFormOfPaymentFormDialog';
import EditFormOfPaymentFormDialog from './forms-of-payment-forms/EditFormOfPaymentFormDialog';
import DeleteFormOfPaymentDialog from './forms-of-payment-forms/DeleteFormOfPaymentDialog';
import {t} from 'ttag';

const FormsOfPayment = () => {

  const [formOfPaymentToCreate, setFormOfPaymentToCreate] = useState(null);
  const [formOfPaymentToEdit, setFormOfPaymentToEdit] = useState(null);
  const [formOfPaymentToDelete, setFormOfPaymentToDelete] = useState(null);

  return (
    <SimpleCard>
      <CardHeader
        title={<Typography variant={'h2'}>{t`Forms of payment`}</Typography>}
        action={<Button onClick={() => setFormOfPaymentToCreate({})} startIcon={<Add/>}>{t`NEW`}</Button>}
      />
      <FormsOfPaymentList
        editHandler={setFormOfPaymentToEdit}
        removeHandler={setFormOfPaymentToDelete}
      />
      {formOfPaymentToCreate && (
        <CreateFormOfPaymentFormDialog
          formOfPayment={formOfPaymentToCreate}
          onClose={() => setFormOfPaymentToCreate(null)}
        />
      )}
      {formOfPaymentToEdit && (
        <EditFormOfPaymentFormDialog
          formOfPayment={formOfPaymentToEdit}
          onClose={() => setFormOfPaymentToEdit(null)}
        />
      )}
      {formOfPaymentToDelete && (
        <DeleteFormOfPaymentDialog
          formOfPayment={formOfPaymentToDelete}
          onClose={() => setFormOfPaymentToDelete(null)}
        />
      )}
    </SimpleCard>
  );
};

export default FormsOfPayment;
