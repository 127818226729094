import { t } from "ttag";
import React, { useEffect } from "react";
import NProgress from "nprogress";
import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { useSataLogin, useSataAuth } from "sata-auth";
import { useHistory } from "react-router-dom";

import logo from "../../layouts/main-layout/sata-logo.png";
import {Box} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "100%",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10)
  },
  caption: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    display: "flex",
    width: "20rem"
  },
  image: {
    maxWidth: "100%",
    width: "35rem",
    maxHeight: "16.75rem",
    height: "auto"
  },
  logo: {
    width: "9.375rem"
  }
}));


const Login = () => {
  const { isAuthenticated, isInitialized, account } = useSataAuth();
  const login = useSataLogin();

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      login();
      let haveKeys = Object.keys(localStorage).toString().includes('clientId')
      let justOnce = localStorage.getItem("justOnce");

      if (haveKeys && !justOnce) {
        localStorage.setItem("justOnce", "true");
        window.location.reload();
      } else {
        localStorage.removeItem("justOnce")
      }
    }

    if (isAuthenticated && account) {
      const redirectTo = window.sessionStorage.getItem('_redirect_');
      window.sessionStorage.removeItem('_redirect_');
      const path = redirectTo ? redirectTo : '/';
      history.push(path);
    }
    // if (isInitialized && isAuthenticated) {
    //   const redirectTo = window.sessionStorage.getItem("_redirect_");
    //   const path = redirectTo ? redirectTo : "/";
    //   history.push(path);
    // }

  }, [ isAuthenticated, isInitialized, login, history, account]);

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h2">{t`Login, please wait...`}</Typography>
      <Box width="20rem">
        <LinearProgress />
      </Box>
      <Typography component="p" className={classes.caption}
                  variant="caption">{t`Contacting Microsoft Azure...`}</Typography>
      <Box
        mt={10}
        display="flex"
        justifyContent="center"
      >
        <img
          alt="Under development"
          className={classes.image}
          src="/static/images/undraw_Secure_server_re_8wsq.svg"
        />

      </Box>
      <Box
        mt={2}
        display="flex"
        justifyContent="flex-end"
        width="24rem"
      >
        <img
          alt="SATA"
          src={logo}
          className={classes.logo}
        />
      </Box>

    </div>
  );
};

export default Login;
