export const type = 'user';
export const userManagementEndPoints = (builder: any) => ({
  // Get list of users by pattern
  getUsersByPattern: builder.mutation({
    query: (pattern: any) => ({
      url: `/users?pattern=${pattern}&rows=9999`,
      method: 'GET',
      destructFn: (d: any) => d?.data?.data
    })
  }),

  //  Get an list of users
  getUsers: builder.query({
    query: (params: any) => ({
      url: `/users`,
      method: 'GET',
      params
    }),
    providesTags: ({ data }: any) => {
      return data
        .map(({ employeeId }: any) => ({ type, id: employeeId }))
        .concat({ type, id: 'list' });
    }
  }),

  //  Add an User
  addUser: builder.mutation({
    query: (user: any) => ({
      url: '/users',
      method: 'POST',
      body: user
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  //  Update an User
  editUser: builder.mutation({
    query: (user: any) => ({
      url: `/users/${user.employeeId}`,
      method: 'PATCH',
      body: user
    }),
    invalidatesTags: ({ employeeId }: any) => [{ type, id: employeeId }]
  }),

  //  Disables user by employee ID
  disableUser: builder.mutation({
    query: ({ employeeId }: any) => ({
      url: `/users/${employeeId}/disable`,
      method: 'POST'
    })
  }),

  //  Enables user by employee ID
  enableUser: builder.mutation({
    query: ({ employeeId }: any) => ({
      url: `/users/${employeeId}/enable`,
      method: 'POST'
    })
  }),

  //  Retrieve user picture by employee ID
  getUserPicture: builder.query({
    query: ({ employeeId }: any) => ({
      url: `/user/${employeeId}/picture.jpg`,
      method: 'GET'
    })
  }),

  //  Retrieve user by employee ID
  getUser: builder.query({
    query: (employeeId :any) => ({
      url: `/user/${employeeId}`,
      method: 'GET'
    }),
    providesTags: ({ employeeId }:any) => {
      return [{ type, id: employeeId }];
    }
  }),

  //  Remove user by employee ID
  deleteUser: builder.mutation({
    query: ({ employeeId }: any) => ({
      url: `/user/${employeeId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  //  Retrieve user that is present in the authorization token
  getCurrentUser: builder.query({
    query: (body: any) => ({
      url: `/users/me`,
      method: 'GET',
      body
    })
  }),

  // Users Azure/AD search
  getAzureUsers: builder.mutation({
    query: (pattern: any) => ({
      destructFn: (d: any) => d?.data?.data,
      url: `/users/search?pattern=${pattern}`,
      method: 'GET'
    })
  })
});

export default userManagementEndPoints;
