import axios from 'axios';

import {API_ENDPOINT_TRANSPORT, MSA_SCOPES_TRANSPORT} from '../../../utils/settings';
import {msalInstance} from "../../../utils";
import {retrieveCurrentTokenPerScope} from "sata-auth";

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT_TRANSPORT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await retrieveCurrentTokenPerScope(msalInstance, MSA_SCOPES_TRANSPORT || '');
    } catch (e) {
    }
    // @ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export {axiosInstance};

export default axiosInstance;
