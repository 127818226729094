// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import { Button } from '@material-ui/core';
import { useSendByEmailRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';

const SendByEmailRefund = ({ refundId }) => {
  const [sendByEmailRefund, { data, error, isLoading }] =
    useSendByEmailRefundMutation();

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        style={{ marginRight: 5 }}
        onClick={() => sendByEmailRefund(refundId)}
      >{t`SEND BY EMAIL`}</Button>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

SendByEmailRefund.propTypes = {};

SendByEmailRefund.defaultProps = {};

export default SendByEmailRefund;
