import { t } from 'ttag';
import React from 'react';
import { UserMenu as Menu } from 'sata-mui-react-components';
import {useSataAuth} from 'sata-auth';
import { MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const UserMenu = () => {
  const { account } = useSataAuth();
  const history = useHistory();
  return (
    <Menu name={account.name} role={account.username}>
      <MenuItem onClick={() => history.push('/logout')}>{ t`Logout` }</MenuItem>
    </Menu>
  );
};

export default UserMenu;
