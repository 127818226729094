// @ts-nocheck
import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import {t} from 'ttag';
import {Controller} from 'react-hook-form';
import FormStepperActions from '../common/stepper/FormStepperActions';
import RefundTypeSelectorField from '../../../common/RefundTypeSelectorField';
import OfficeSelectorField from '../../../common/OfficeSelectorField';

const Step1Form = () => {

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <Controller
          name={'refundType'}
          render={({field, fieldState: {error}}) => (
            <RefundTypeSelectorField
              {...field}
              label={t`Type`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              required
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'office'}
          render={({field, fieldState: {error}}) => (
            <OfficeSelectorField
              {...field}
              label={t`Currency to be refunded`}
              color={'primary'}
              variant={'outlined'}
              // onChange={office => formik.setFieldValue('office', office)}
              // value={formik.values.office}
              error={Boolean(error)}
              helperText={error?.message}
              multiline
              required
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={12} item>
        <Controller
          name={'reason'}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Reason`}
              color={'primary'}
              variant={'outlined'}
              minRows={2}
              maxRows={2}
              error={Boolean(error)}
              helperText={error?.message}
              multiline
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={12} item>
        <FormStepperActions/>
      </Grid>
    </Grid>

  );
};

export default Step1Form;
