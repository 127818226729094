// @ts-nocheck
import PropTypes from 'prop-types';
import {useCheckResponsibilityTermQuery, useUpdateResponsibilityTermMutation} from '../../store/apis/ccs';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {Button} from 'sata-mui-react-components';
import React, {useState} from 'react';
import {useHistory} from 'react-router';

export const messageTemplateKey = 'USER_NAME_INPUT';
export const messageTemplate = t`Eu, titular do username ${messageTemplateKey} assumo o compromisso de confidencialidade sobre todos os dados pessoais (NIF) e informação de residência a que tenha acesso na validação de condições de elegibilidade no âmbito da Tarifa Açores, e que o acesso aplicacional só se destina a este fim, bem declaro que a informação reportada só será usada com esta finalidade.`;

export const ResidentValidatorTermsGuard = ({children}) => {
  const history = useHistory();
  const {data: isAccept, isLoading, error} = useCheckResponsibilityTermQuery();
  const [update, updateResponse] = useUpdateResponsibilityTermMutation();
  const [providedUsername, setProvidedUsername] = useState('');
  const [providedUsernameError, setProvidedUsernameError] = useState(false);

  if (isAccept) return children;

  const [preMessage, postMessage] = messageTemplate.split(messageTemplateKey);
  const onAccept = () => update({message: messageTemplate.replace(messageTemplateKey, providedUsername), providedUsername, isAccept: true});
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Dialog maxWidth={'sm'} onClose={history.goBack} open={isAccept === undefined ? false : !isAccept}>
        <DialogTitle>
          <Typography variant={'h3'} component={'div'}>
            {t`Terms & Conditions`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{lineHeight: '1.5em', fontSize: '1rem'}}>
          {preMessage}
          <TextField
            placeholder={t`Insert username`}
            variant={'standard'}
            size={'small'}
            value={providedUsername}
            error={providedUsernameError}
            required
            onBlur={() => setProvidedUsernameError(!providedUsername)}
            onChange={(e) => {
              setProvidedUsername(e.target.value);
              setProvidedUsernameError(!e.target.value);
            }}
            style={{paddingTop: '2.5px'}}
            inputProps={{style: {padding: '0px 0px 0px'}}}
          />
          {postMessage}
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button
              variant={'contained'}
              disabled={!providedUsername || updateResponse.isSuccess || updateResponse.isLoading}
              onClick={onAccept}
              m={3}
            >
              {t`Accept`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback isLoading={isLoading} error={error}/>
      <RTKQueryFeedback {...updateResponse}/>
    </Box>
  );
};

ResidentValidatorTermsGuard.propTypes = {
    children: PropTypes.any
};

ResidentValidatorTermsGuard.defaultProps = {
    children: null
};

export default ResidentValidatorTermsGuard;
