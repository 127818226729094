import React, { useState } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  ApplicationBar,
  SideBar
} from "sata-mui-react-components";

import logo from "./main-layout/sata-logo.png";
import theme from "../theme/theme";
import ApplicationMainNav from "../components/navigation/ApplicationMainNav";
import UserMenu from './main-layout/UserMenu';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "3.75rem",
    display: "flex"
  },
  content: {
    flexGrow: 1
  },
  rootPage:{
    width: '100%'
  }
}));

const MainLayout = ({ children, match }) => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const isWide = window.screen.availWidth >= theme.breakpoints.values.lg;

  const [open, setOpen] = useState(isWide);
  const isShortSidebar = !matches && !open;

  const handleSideBarMenuClick = () => {
    const nextState = !open;
    setOpen(nextState);
  };

  return (
    <div className={classes.root}>
      <ApplicationBar appName="Contact Center Shell" logo={logo} onMenuClick={handleSideBarMenuClick}><UserMenu /></ApplicationBar>
      <SideBar title="Navigation" open={open} onMenuClick={handleSideBarMenuClick}>
        <ApplicationMainNav isShortSidebar={isShortSidebar} currentPath={match.url} />
      </SideBar>
      <div className={classes.rootPage}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
