import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const LabeledDetail = ({ label, children, isLoading }) => {
  return (
    <>
      <Typography variant='h5' component='h2'>
        {label}
      </Typography>
      {!isLoading && (
        <Typography color='textSecondary'>
          {children}&nbsp;
        </Typography>
      )}
      {isLoading && <Skeleton width='60%' />}
    </>
  );
};

LabeledDetail.propTypes = {
  label: PropTypes.string,
  children: PropTypes.string,
  isLoading: PropTypes.bool
};

LabeledDetail.defaultProps = {
  isLoading: false
};

export default LabeledDetail;
