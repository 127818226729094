import React from 'react';
import {Redirect} from 'react-router-dom';
import {TransportFacilities, TransportFacilitiesCreate} from "./transport-facilities/pages";
import ViewRefundPage from './scenes/refunds/refund-forms/view-refund-form/ViewRefundPage';
import AuthorizationGuard from './components/guards/AuthorizationGuard';
import {ENTRIES} from './components/navigation/ApplicationMainNav';
import ResidentValidatorPage from "./scenes/resident-validator";
import {CreateRefundPage} from './scenes/refunds/refund-forms';
import Unauthorized from './components/errors/Unauthorized';
import {Dashboard, Refunds, Settings} from './scenes';
import {Login, Logout} from './scenes/authentication';
import NotFound from './components/errors/NotFound';
import {Auth} from './components/guards';
import {MainLayout} from './layouts';

const routes = [
  {
    exact: true,
    path: '/404',
    component: NotFound
  },
  {
    exact: true,
    path: '/401',
    component: Unauthorized
  },
  {
    exact: true,
    path: '/auth',
    component: Login
  },
  {
    exact: true,
    path: '/logout',
    component: Logout
  },
  {
    path: '*',
    layout: MainLayout,
    guard: Auth,
    routes: [
      {
        exact: true,
        path: ['/', '/dashboard'],
        component: Dashboard,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.dashboard.roles} children={children}/>
      },
      {
        exact: true,
        path: '/refunds',
        component: Refunds,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.refunds.roles} children={children}/>
      },
      {
        exact: true,
        path: '/refunds/create',
        component: CreateRefundPage,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.refunds.roles} children={children}/>
      },
      {
        exact: true,
        path: '/refunds/:refundId',
        component: ViewRefundPage,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.refunds.roles} children={children}/>
      },
      {
        exact: true,
        path: '/resident-validator',
        component: ResidentValidatorPage,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.residentValidator.roles} children={children}/>
      },
      {
        exact: true,
        path: '/transport-facilities',
        component: TransportFacilities,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.transportFacilities.roles} children={children}/>
      },
      {
        exact: true,
        path: '/transport-facilities/create',
        component: TransportFacilitiesCreate,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.transportFacilities.roles} children={children}/>
      },
      {
        exact: true,
        path: '/settings',
        component: () => <Redirect to={'/settings/employees'}/>,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.settings.roles} children={children}/>
      },
      {
        path: '/settings',
        component: Settings,
        guard: ({children}) => <AuthorizationGuard userHasSomeOf={ENTRIES.settings.roles} children={children}/>
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  }
];

export default routes;
