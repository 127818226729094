// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {AlertDialogModal, Button} from 'sata-mui-react-components';
import {useDeleteOfficeMutation} from '../../../../store/apis/ccs';
import {Box, DialogActions, DialogContent} from '@material-ui/core';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';

const DeleteOfficeDialog = ({office, open, onClose, ...rest}) => {

  const [deleteOffice, {data, error, isLoading}] = useDeleteOfficeMutation();

  return (
    <AlertDialogModal title={t`Office`} variant={'error'} open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogContent dividers>
        <Box p={1}>
          {t`Do you really want to delete this item?`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={1}>
          <Button variant={'contained'} type={'error'} onClick={() => deleteOffice(office).then(r => !r.error && onClose())}>{t`Delete`}</Button>
        </Box>
        <Box mx={1}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
      </DialogActions>
    </AlertDialogModal>
  );
};

DeleteOfficeDialog.propTypes = {
  office: PropTypes.shape({
    officeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteOfficeDialog.defaultProps = {
  office: null,
  open: true,
  onClose: null,
};

export default DeleteOfficeDialog;
