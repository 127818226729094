import React from 'react';
import {BasicTable} from 'sata-mui-react-components';
import {columns} from './refundSubjectsListColumns';
import PropTypes from 'prop-types';
import {setRefundSubjectToDelete, setRefundSubjectToEdit} from '../../../common/store/form-state';

const RefundSubjectsList = ({refundSubjects, isLoading, editable}) => {
  return (
    <BasicTable
      hideFooter
      density={'compact'}
      autoHeight
      getRowId={({documentNumber}) => documentNumber}
      onRowDoubleClick={({row}) => setRefundSubjectToEdit(row)}
      columns={columns({
        viewHandler: setRefundSubjectToEdit,
        deleteHandler: setRefundSubjectToDelete,
        editable
      })}
      rows={refundSubjects}
      pageSize={100}
      rowsPerPageOptions={[100]}
      loading={isLoading}
      disableSelectionOnClick
    />
  );
};

RefundSubjectsList.propTypes = {
  refundSubjects: PropTypes.array,
  isLoading: PropTypes.bool,
  editable: PropTypes.bool,
};

RefundSubjectsList.defaultProps = {
  refundSubjects: [],
  isLoading: false,
  editable: true,
};

export default RefundSubjectsList;
