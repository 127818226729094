import PropTypes from 'prop-types';
import {FormControl, FormHelperText, InputLabel} from '@mui/material';

const LabeledField = ({label, error, helperText, children, disabled, required, size ,sx}) => {
  return (
    <FormControl sx={sx} error={Boolean(error)} required={required} disabled={disabled} size={size} fullWidth>
      <InputLabel>{label}</InputLabel>
      {children}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

LabeledField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium'])
};

LabeledField.defaultProps = {
  label: null,
  error: false,
  helperText: null,
  required: false,
  disabled: false,
  children: null,
  size: 'medium'
};

export default LabeledField;
