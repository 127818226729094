// @ts-nocheck
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';

import {Attachments,} from '../../../../../components/attachments';
import {STATUSES} from '../../../../../components/status/Status';
import RTKQueryFeedback from '../../../../../components/common/RTKQueryFeedback';
import AttachmentsDownloads from './refund-attachments-download/AttachmentsDownloads';
import {useAddAttachmentsMutation, useGetAttachmentsQuery} from '../../../../../store/apis/ccs';
import AttachmentsDownloadsSkeleton from './refund-attachments-download/AttachmentsDownloadsSkeleton';

const AttachmentsRefundView = ({ state, attachments, refundId }) => {
  const { data: dataAttachments, isLoading: isLoadingAttachments } =
    useGetAttachmentsQuery(refundId);
  const [addAttachments, { data, error, isLoading }] =
    useAddAttachmentsMutation();

  // eslint-disable-next-line
  const [files, setFiles] = useState(attachments);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Card>
      <CardHeader title={t`Attachments`} />
      <CardContent>
        <Attachments
          dropAttachmentsLabel={t`Drop Attachments`}
          allowedFileTypesLabel={t`Allowed File Types`}
          onChange={(files) => setFiles(files)}
          error={false}
          disabled={(state !== STATUSES.PROCESSED.name) && (state !== STATUSES.PENDING.name)}
          onFilesRejected={(files) => {
            files.map((f) => {
              const successMessage = `${f.name}: ${f.reason}`;
              return enqueueSnackbar(successMessage, { variant: 'error' });
            });
          }}
          onSizeLimitReached={(files) => {
            files.map((f) => {
              const successMessage = `${f.name}: ${f.reason}`;
              return enqueueSnackbar(successMessage, { variant: 'error' });
            });
          }}
          onLoadFiles={(files) => {
            addAttachments({refundId, files:files});
            setFiles(files);
          }}
        />
        {isLoadingAttachments && <AttachmentsDownloadsSkeleton />}
        {!isLoadingAttachments && <AttachmentsDownloads refundId={refundId} attachments={dataAttachments}/>}
      </CardContent>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </Card>
  );
};

AttachmentsRefundView.propTypes = {
  attachments: PropTypes.array
};

AttachmentsRefundView.defaultProps = {
  attachments: []
};

export default AttachmentsRefundView;
