import React from 'react';
import {BreadCrumbs, Page} from 'sata-mui-react-components';
import {ResidentValidator} from "./index";
import ResidentValidatorTermsGuard from './ResidentValidatorTermsGuard';
import {Box} from "@mui/material";

const ResidentValidatorPage = () => (
  <Page breadcrumbs={<BreadCrumbs list={[{name: 'Home', link: '/'}, {name: 'Resident Validator'}]}/>}>
    <Box sx={{width: '100%'}}>
      <ResidentValidatorTermsGuard>
        <ResidentValidator/>
      </ResidentValidatorTermsGuard>
    </Box>
  </Page>
);

export default ResidentValidatorPage;
