import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import Status from '../status';

const History = ({ avatar, name, date, state }) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={date} />
      <ListItemSecondaryAction>
        <Status state={state} variant='standard'/>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

History.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  state: PropTypes.string
};

History.defaultProps = {
  avatar: 'https://i.imgur.com/mCHMpLT.png'
};

export default History;
