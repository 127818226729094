// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {Button} from 'sata-mui-react-components';
import {useFormik} from 'formik';
import IssueOfficeForm from './IssueOfficeForm';
import {issueOfficeValidationSchema} from './helpers';
import {useEditIssueOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const EditIssueOfficeFormDialog = ({issueOffice, open, onClose, ...rest}) => {

  const [editIssueOffice, {data, error, isLoading}] = useEditIssueOfficeMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: issueOffice,
    validationSchema: issueOfficeValidationSchema,
    onSubmit: values => editIssueOffice(values).then(r => !r.error && onClose()),
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogTitle>
        <Typography variant={'h2'} component={'div'}>
          {t`Issue office`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1}>
          <IssueOfficeForm formik={formik}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={.4}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit}>{t`Save`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditIssueOfficeFormDialog.propTypes = {
  issueOffice: PropTypes.shape({
    issueOfficeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

EditIssueOfficeFormDialog.defaultProps = {
  issueOffice: null,
  open: true,
  onClose: null,
};

export default EditIssueOfficeFormDialog;
