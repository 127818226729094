import {t} from 'ttag';
import Amount from '../Amount';

export const columns = ({editable}) => [
  {
    field: 'type',
    headerName: t`Type`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => {
      const {value, currency} = row || {};
      return <Amount value={value} currency={currency}/>;
    }
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({row}) => {
      const {currentValue: value, currency} = row || {};
      return <Amount value={value} currency={currency}/>;
    }
  },
];
