import {t} from 'ttag';
import React from 'react';
import {Add} from '@mui/icons-material';
import {useHistory} from 'react-router';
import {useMediaQuery} from '@material-ui/core';
import {BreadCrumbs, Page} from 'sata-mui-react-components';
import {Button, CardHeader, Divider, Paper, Stack, Theme, Typography} from '@mui/material';
import {TransportFacilitiesHistory} from './transport-facilities';
import palette from "../../../theme/palette";

const breadcrumbs = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Industry Discount'
  }
];

export const TransportFacilities = () => {
  const navigate = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Page breadcrumbs={<BreadCrumbs list={breadcrumbs} />}>
      <Paper elevation={3}>
        <CardHeader
          sx={{display: isMobile ? 'block' : 'flex'}}
          title={
            <Typography
              sx={{color: palette.secondary.main, fontSize: '1.375rem', mb: isMobile ? 2 : 0, fontFamily: 'NeoSansProMedium'}}
            >{t`Industry Discount`}</Typography>
          }
          action={
            <Stack direction={isMobile ? 'column' : 'row'} columnGap={2} rowGap={isMobile ? 1 : 0}>
              <Button
                color={'primary'}
                variant={'outlined'}
                sx={{fontFamily: 'NeoSansProMedium', borderWidth: 2, '&:hover': {borderWidth: 2}}}
                onClick={() => window.open('https://sata.sharepoint.com/sites/spo_biblioteca/Recursos%20Humanos/Forms/AllItems.aspx?id=%2Fsites%2Fspo%5Fbiblioteca%2FRecursos%20Humanos%2FRegulamentos%2FFacilidades%20Transporte&viewid=6dc409da%2D73e7%2D4a7f%2D80aa%2Ddefa7a9d68f4&OR=Teams%2DHL&CT=1675428855345&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0MS8yMzAxMDEwMDkxMyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D')}
              >{t`Regulation`}</Button>
              <Button
                startIcon={<Add />}
                color={'primary'}
                variant={'contained'}
                sx={{fontFamily: 'NeoSansProMedium'}}
                onClick={() => navigate.push('/transport-facilities/create')}
              >{t`New Request`}</Button>
            </Stack>
          }
        />
        <Divider />
        <TransportFacilitiesHistory />
      </Paper>
    </Page>
  );
};

export default TransportFacilities;
