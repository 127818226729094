import {Box, InputLabel, MenuItem, Stack, TextField, Typography} from "@mui/material";
import {t} from "ttag";
import React from "react";
import palette from "../../../theme/palette";

const BeneficiaryFacilityTypeSelector = ({row, onChange}) => {
    const onClick = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.target.value === '' ? onChange({...row, selectedFacilityId: undefined}) : onChange({...row, selectedFacilityId: e.target.value});
    }

  if (!row.beneficiaryFamilyMember?.familyMember?.hasTransportationBenefits) {
    return (
      <Box display={'inline-flex'} alignContent={'center'} gap={1}>
        <Typography color={'error'} variant={'body2'}>{t`Inibido`}</Typography>
      </Box>
    );
  }

    return (
        <TextField
            InputLabelProps={{shrink: false, color: 'primary'}}
            label={row.selectedFacilityId ? '' : t`Select`}
            value={row.selectedFacilityId || ''}
            variant={'outlined'}
            onChange={onClick}
            color={'primary'}
            size={'small'}
            fullWidth
            select
        >
            <MenuItem key={-1} value={''}>
              <Typography sx={{color: palette?.secondary.main, fontFamily: 'NeoSansProRegular'}}>
                {t`None`}
              </Typography>
            </MenuItem>
            {row.allowanceDefinitionUsage?.map((usage, idx) => (
                    usage?.facilityType && !usage.facilityType?.isColdVacations &&
                    <MenuItem key={idx} value={usage.facilityType?.id} disabled={!(usage.total === 0 || usage.available > 0)}>
                        <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography  sx={{color: palette?.secondary.main, fontFamily: 'NeoSansProRegular',textDecoration: !(usage.total === 0 || usage.available > 0) ? 'line-through' : 'inherit'}} color={'secondary'}>
                                {usage?.facilityType?.designation}
                            </Typography>
                            {row.selectedFacilityId !== usage.facilityType?.id &&
                                <Typography align={'right'} variant={'body2'} color={'accordion.label'}>
                                    {usage.total !== 0 ? usage.available : t`Ilimitado`}
                                </Typography>
                            }
                        </Stack>
                    </MenuItem>
                )
            )}
            {row.allowanceDefinitionUsage?.find((u) => u?.facilityType?.isColdVacations) &&
                <InputLabel key={9999} sx={{mt: 1, ml: 2, fontSize:'0.8em'}}>{t`FÉRIAS FRIAS`}</InputLabel>
            }
            {row.allowanceDefinitionUsage?.map((usage,idx) => (
                    usage?.facilityType?.isColdVacations &&
                    <MenuItem key={9999 + idx} value={usage?.facilityType?.id} disabled={!(usage.total === 0 || usage.available > 0)}>
                        <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'body1'} sx={{textDecoration: !(usage.total === 0 || usage.available > 0) ? 'line-through' : 'inherit'}} color={'secondary'}>
                                {usage?.facilityType?.designation}
                            </Typography>
                            {row?.selectedFacilityId !== usage?.facilityType?.id &&
                                <Typography align={'right'} variant={'body2'} color={'accordion.label'}>
                                    {usage.total !== 0 ? usage.available : t`Ilimitado`}
                                </Typography>
                            }
                        </Stack>
                    </MenuItem>
                )
            )}
        </TextField>
    );
};

export default BeneficiaryFacilityTypeSelector;
