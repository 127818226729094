import {makeStyles} from "@material-ui/core";

const withStyles = makeStyles(theme => ({
    cardHeader: {
        backgroundColor: 'rgba(58, 80, 132, 1)',
    },
    cardHeaderTitle: {
        color: 'white',
        fontWeight: 'bold',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '-10px',
        padding: '0 6px'
    },
    vatListContainer: {
        marginTop: '15px',
    },
    vatWrapper: {
        margin: '20px 0',
    },
    deleteVatIcon: {
        color: 'rgba(0, 0, 0, 0.50)',
        cursor: 'pointer',
    },
    bold: {
        color: 'rgba(0, 0, 0, 0.50)',
        fontSize: '18px',
        letterSpacing: '1px',
    },
    vatObservation: {
        color: 'rgba(0, 0, 0, 0.70)',
        fontSize: '14px',
    },
    validVatIcon: {
        color: 'rgba(58, 131, 64, 1)',
    },
    invalidVatIcon: {
        color: 'rgba(203, 47, 38, 1)',
    }
}));

export default withStyles;
