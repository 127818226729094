import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {API_ENDPOINT_TRANSPORT} from "../../utils/settings";
import {giveRowAUniqueId} from "../../utils/table/table-utils";
import {IListParams} from "./utils/utils.types";

export const type = 'companies';
export const companyEndpoints = (builder:EndpointBuilder<any, any, any>) => ({
  getCompanies: builder.query<any, IListParams>({
    query: (params) => ({
      url: `${API_ENDPOINT_TRANSPORT}/companies`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
  }),
});

export default companyEndpoints;
