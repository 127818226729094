// @ts-nocheck
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog, faListUl, faPlane, faTachometerAlt, faUserCheck} from '@fortawesome/free-solid-svg-icons';
import {SidebarMenu} from 'sata-mui-react-components';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {hasSomeOf, ROLES} from '../../utils/user/user-utils';
import {useSataAuth} from "sata-auth";
import {useGetCurrentUserQuery} from "../../store/apis/ccs";
import {ConnectingAirports} from "@mui/icons-material";
import SidebarDropDownLink from "../SidebarDropDownLink";

export const ENTRIES = {
  dashboard: {
    title: 'Dashboard',
    icon: <FontAwesomeIcon icon={faTachometerAlt} />,
    path: '/dashboard',
    exact: true,
    roles: Object.values(ROLES)
  },
  refunds: {
    title: 'Refunds',
    icon: <FontAwesomeIcon icon={faListUl} />,
    path: '/refunds',
    exact: true,
    roles: [ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]
  },
  transportFacilities: {
    title: 'Industry Discount',
    icon: <FontAwesomeIcon icon={faPlane} />,
    path: '/transport-facilities',
    exact: true,
    roles: [ROLES.CCS_ADMIN, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]
  },
  settings: {
    title: 'Settings',
    icon: <FontAwesomeIcon icon={faCog} />,
    path: '/settings',
    exact: false,
    roles: [ROLES.CCS_ADMIN]
  },
  residentValidator: {
    title: 'Resident Validator',
    icon: <FontAwesomeIcon icon={faUserCheck} />,
    path: '/resident-validator',
    exact: true,
    roles: Object.values(ROLES)
  },
}

const ApplicationMainNav = ({isShortSidebar, currentPath}) => {
  const {data: user} = useGetCurrentUserQuery();
  const {account, isAuthenticated} = useSataAuth();
  const history = useHistory();


  if ((!account && isAuthenticated) || !user) {
    return null;
  }

  let entries = {};

  if (hasSomeOf(user, ENTRIES.dashboard.roles)) entries.dashboard = ENTRIES.dashboard;
  if (hasSomeOf(user, ENTRIES.residentValidator.roles)) entries.residentValidator = ENTRIES.residentValidator;
  if (hasSomeOf(user, ENTRIES.refunds.roles)) entries.refunds = ENTRIES.refunds;
  if (hasSomeOf(user, ENTRIES.transportFacilities.roles)) entries.transportFacilities = ENTRIES.transportFacilities;
  if (hasSomeOf(user, ENTRIES.settings.roles)) entries.settings = ENTRIES.settings;

  return (
    <>
      <SidebarMenu entries={entries} shortSidebar={isShortSidebar} currentPath={currentPath} onClick={history.push} />
      {hasSomeOf(user, ENTRIES.residentValidator.roles) ?
        (<SidebarDropDownLink
        path={'/links'}
        text={`Cash & Miles`}
        icon={<ConnectingAirports />}
        subLinks={[
          {path: 'https://cashmiles.sata.pt', text: `Cash & Miles APP`, externalLink: true},
          {path: '/cash_and_miles.xlsx', text: `Cash & Miles Ratio`, externalLink: true},
        ]}
      />)
        : null}
    </>
  );
};

ApplicationMainNav.propTypes = {
  isShortSidebar: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired
};

ApplicationMainNav.defaultProps = {
  isShortSidebar: false,
  currentPath: '/'
};

export default ApplicationMainNav;
