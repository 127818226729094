// @ts-nocheck
import {useState} from 'react';
import {BasicTable, SearchBar} from 'sata-mui-react-components';
import {columns} from './formsOfPaymentListHelper';
import {useGetFormsOfPaymentQuery} from '../../../store/apis/ccs';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';

const FormsOfPaymentList = ({editHandler, removeHandler}) => {
  const [pattern, setPattern] = useState(null);
  const {data = [], error, isLoading, isFetching} = useGetFormsOfPaymentQuery({pattern});

  const handlePatternInput = (e) => {
      setPattern(e.target.value)
  }

  return (
    <>
      <RTKQueryFeedback error={error}/>
      <SearchBar
        value={pattern}
        onChange={handlePatternInput}
      />
      <BasicTable
        autoHeight
        getRowId={({formOfPaymentId}) => formOfPaymentId}
        columns={columns({editHandler, removeHandler})}
        rows={data}
        pageSize={100}
        rowsPerPageOptions={[100]}
        loading={isLoading || isFetching}
        disableSelectionOnClick
      />
    </>

  );
};

FormsOfPaymentList.propTypes = {
  editHandler: PropTypes.func.isRequired,
  removeHandler: PropTypes.func.isRequired,
};

export default FormsOfPaymentList;
