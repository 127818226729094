import React from 'react';
import {useWatch} from 'react-hook-form';
import {useSnapshot} from 'valtio';
import formState, {setRefundSubjectToImport} from '../../../common/store/form-state';
import RefundSubjectImportForm from './RefundSubjectImportForm';

const RefundSubjectImportFormConnected = () => {

  const refundSubjects = useWatch({name: 'refundSubjects'});
  const {form: {refundType, office}} = useSnapshot(formState);

  return (
    <RefundSubjectImportForm
      refundType={refundType}
      officeCode={office?.officeCode}
      refundSubjects={refundSubjects}
      onImport={setRefundSubjectToImport}
    />
  );
};

export default RefundSubjectImportFormConnected;
