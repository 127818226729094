import {t} from "ttag";
import moment from "moment";

const useCalendarShortcuts = () => {
    return [
        {
            label: t`Today`,
            startDate: new Date(),
            endDate: new Date()
        },
        {
            label: t`Yesterday`,
            startDate: moment().subtract(1, "day"),
            endDate: moment().subtract(1, "day")
        },
        {
            label: t`This week`,
            startDate: moment().startOf("week"),
            endDate: moment().endOf("week")
        },
        {
            label: t`Last week`,
            startDate: moment().subtract(1, "week").startOf("week"),
            endDate: moment().subtract(1, "week").endOf("week")
        },
        {
            label: t`This month`,
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month")
        },
        {
            label: t`Last month`,
            startDate: moment().subtract(1, "month").startOf("month"),
            endDate: moment().subtract(1, "month").endOf("month")
        },
    ];
}

export default useCalendarShortcuts;