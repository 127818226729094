// @ts-nocheck
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, DialogActions, DialogContent, Dialog, DialogTitle, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {AlertDialogModal, Button} from 'sata-mui-react-components';
import {useFormik} from 'formik';
import OfficeForm from './OfficeForm';
import {officeValidationSchema} from './officeFormValidationSchema';
import {useCreateOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const CreateOfficeFormDialog = ({office, open, onClose, ...rest}) => {
  const [officeAlert, setOfficeAlert] = useState(false);
  const [createOffice, {data, error, isLoading}] = useCreateOfficeMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: office,
    validationSchema: officeValidationSchema,
    onSubmit: (values) => {
      if (officeAlert) {
        createOffice(values).then((r) => {
          if (!r.error) {
            setOfficeAlert(false);
            onClose();
          }
        });
      } else {
        setOfficeAlert(true);
      }
    }
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} />
      <DialogTitle>
        <Typography variant={'h2'} component={'div'}>
          {t`Office`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <OfficeForm formik={formik} />
      </DialogContent>
      <DialogActions>
        <Box p={1} px={0.4}>
          <Button variant={'outlined'} onClick={onClose} m={3}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={0.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit} m={3}>{t`Create`}</Button>
        </Box>
      </DialogActions>
      <AlertDialogModal
        open={officeAlert}
        variant={'warning'}
        title={t`Warning`}
      >
        <DialogContent>
          {t`Esta ação é efectiva apenas se o office introduzido ja estiver previamente configurado.`}
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant={'contained'} onClick={formik.handleSubmit}>{t`Create`}</Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant={'outlined'} onClick={() => setOfficeAlert(false)}>{t`Cancel`}</Button>
          </Box>
        </DialogActions>
      </AlertDialogModal>
    </Dialog>
  );
};

CreateOfficeFormDialog.propTypes = {
  office: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

CreateOfficeFormDialog.defaultProps = {
  office: null,
  open: true,
  onClose: null
};

export default CreateOfficeFormDialog;
