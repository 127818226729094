import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';
import {t} from 'ttag';

const FormOfPaymentForm = ({formik}) => {

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={4} item>
        <TextField
          label={t`Name`}
          name={'name'}
          color={'primary'}
          variant={'outlined'}
          onChange={formik.handleChange}
          value={formik.values?.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name || ''}
          required
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <TextField
          label={t`Code`}
          name={'code'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('code', e.target.value.toUpperCase())}
          value={formik.values?.code || ''}
          error={Boolean(formik.errors.code)}
          helperText={formik.errors.code || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <TextField
          label={t`Amadeus Code`}
          name={'amadeusCode'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('amadeusCode', e.target.value.toUpperCase())}
          value={formik.values?.amadeusCode || ''}
          error={Boolean(formik.errors.amadeusCode)}
          helperText={formik.errors.amadeusCode || ''}
          required
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

FormOfPaymentForm.propTypes = {
  formik: PropTypes.shape({}),
};

FormOfPaymentForm.defaultProps = {
  formik: {}
};

export default FormOfPaymentForm;
