// @ts-nocheck
import React from "react";
import { Page } from "sata-mui-react-components";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { Typography, Box, Button, Container, makeStyles } from "@material-ui/core";

import logo from "../layouts/main-layout/sata-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 40,
    paddingBottom: 80
  },
  image: {
    maxWidth: "100%",
    width: "35rem",
    maxHeight: "18.75rem",
    height: "auto"
  }
}));

const Welcome = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Box
          mb={8}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="SATA"
            src={logo}
          />
        </Box>
        <Typography align="center" variant="h2">Welcome, your are ready to go!</Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          This is a welcome page where you can check if your new SATA's web app was successfully created.
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          Check that all the theme colors are ok and you don't have any errors in the console.
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/static/images/undraw_welcoming_xvuq.svg`}
          />

        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/dashboard"
            variant="outlined"
          >
            Login with Microsoft Azure &nbsp; &nbsp;<FontAwesomeIcon size="2x" icon={faWindows} />
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default Welcome;
