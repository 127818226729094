import React from 'react';
import {Typography} from '@material-ui/core';
import {t} from 'ttag';
import {useSnapshot} from 'valtio';
import formState from '../common/store/form-state';
import {useWatch} from 'react-hook-form';

const Step1Label = () => {

  const {step} = useSnapshot(formState);

  if (step > 0) {
    return (
      <Typography variant={'caption'}>
        <b>{t`Refund`}</b> - <Step1LabelDetails/>
      </Typography>
    );
  }

  return <b>{t`Refund`}</b>;
};

export const Step1LabelDetails = () => {
  const [refundType, office, reason] = useWatch({name: ['refundType', 'office', 'reason']});

  return <i>( {refundType?.toUpperCase()} - {office?.description} - {reason} )</i>;
}

export default Step1Label;
