import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import RefundSubjectInfo from './form/views/RefundSubjectInfo';
import RefundSubjectRoutes from './form/views/RefundSubjectRoutes';
import RefundSubjectTaxes from './form/views/RefundSubjectTaxes';
import RefundSubjectAmounts from './form/views/refund-subject-amounts/RefundSubjectAmounts';
import RefundSubjectRefundableTaxes from './form/views/refund-subject-refundable-taxes/RefundSubjectRefundableTaxes';
import RefundSubjectFormsOfPayment from './form/views/refund-subject-forms-of-payment/RefundSubjectFormsOfPayment';
import {RefundSubjectTotalCard} from './form/views/RefundSubjectTotal';
import AtcMaskConnected from './form/views/atc-mask/AtcMaskConnected';

const RefundSubjectForm = ({editable}) => {

  return (
    <Grid spacing={1} container>
      <Grid xs={12} md={12} lg={6} item>
        <Grid spacing={3} container>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectInfo/>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectRoutes/>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <AtcMaskConnected/>
            <RefundSubjectTaxes/>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={12} lg={6} item>
        <Grid spacing={3} container>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectAmounts editable={editable}/>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectRefundableTaxes editable={editable}/>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectFormsOfPayment editable={editable}/>
          </Grid>
          <Grid xs={12} md={12} lg={12} item>
            <RefundSubjectTotalCard/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RefundSubjectForm.propTypes = {
  editable: PropTypes.bool,
};

RefundSubjectForm.defaultProps = {
  editable: true,
};

export default RefundSubjectForm;
