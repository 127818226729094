import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  AuthorizationsListParams, IAuthorization,
  IAuthorizationList,
  IMotiveList,
  IRequestDTO,
  ITransportFacilitiesPerBeneficiary,
  TransportFacilitiesListParams
} from './transportFacilityEndpoints.types';
import {IListParams} from './utils/utils.types';
import {giveRowAUniqueId} from '../../utils/table/table-utils';
export const type = 'transport-facilities';

export const transportFacilityEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getTransportFacilitiesPerBeneficiary: builder.query<
    ITransportFacilitiesPerBeneficiary,
    TransportFacilitiesListParams
  >({
    query: (params) => ({
      url: `/transport-facilities-per-beneficiary`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: () => [{type, id: 'list'}]
  }),

  getTransportFacilitiesPerEmployee: builder.mutation<ITransportFacilitiesPerBeneficiary, TransportFacilitiesListParams>({
    query: (params) => ({
      url: `/transport-facilities-per-beneficiary/without-contingent/${params.employeeId}`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    invalidatesTags: [{type, id: 'list'}]
  }),

  getTransportFacilitiesMotives: builder.query<IMotiveList, IListParams>({
    query: (params) => ({
      url: `/motives`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => data?.data,
    providesTags: () => [{type, id: 'list'}]
  }),

  putTransportFacilityRequest: builder.mutation<ITransportFacilitiesPerBeneficiary, IRequestDTO>({
    query: (body) => ({
      url: `/requests`,
      method: 'PUT',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getTransportFacilitiesPerBeneficiary'}]
  }),

  putTransportFacilityRequestPerEmployee: builder.mutation<ITransportFacilitiesPerBeneficiary, IRequestDTO>({
    query: (body) => ({
      url: `/requests/without-contingent/user/${body.employeeId}`,
      method: 'PUT',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'getTransportFacilitiesPerBeneficiary'}, {type, id: 'authorizationsList'}]
  }),

  getAuthorizationss: builder.query<IAuthorizationList, AuthorizationsListParams>({
    query: (params) => ({
      url: `/authorizations`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: () => [{type, id: 'authorizationsList'}]
  }),

  getAuthorizations: builder.query<IAuthorizationList, AuthorizationsListParams>({
    query: (params) => ({
      url: `/authorizations/user/${params.employeeId}`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => giveRowAUniqueId(data?.data),
    providesTags: () => [{type, id: 'authorizationsList'}]
  }),

  cancelAuthorization: builder.mutation<IAuthorization, IAuthorization>({
    query: (params) => ({
      url: `/authorizations/${params?.id}/cancel`,
      method: 'PATCH'
    }),
    invalidatesTags: [{type, id: 'authorizationsList'}]
  }),

  refundAuthorization: builder.mutation<IAuthorization, IAuthorization>({
    query: (params) => ({
      url: `/authorizations/${params?.id}/refund`,
      method: 'PATCH'
    }),
    invalidatesTags: [{type, id: 'authorizationsList'}]
  }),

  consumeAuthorization: builder.mutation<IAuthorization, IAuthorization>({
    query: (body) => ({
      url: `/authorizations/consume`,
      method: 'PATCH',
      body
    }),
    invalidatesTags: [{type, id: 'authorizationsList'}]
  })


});

export default transportFacilityEndpoints;
