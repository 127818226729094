// @ts-nocheck
import {configureStore} from '@reduxjs/toolkit';
import {ENABLE_REDUX_DEV_TOOLS} from '../utils/settings';
import ccsApi from './apis/ccs';
import transactionsApi from '../transport-facilities/api';
import app from './slices/app';

const store = configureStore({
  reducer: {
    app,
    [ccsApi.reducerPath]: ccsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
  },
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ccsApi.middleware).concat(transactionsApi.middleware)
});

export default store;
