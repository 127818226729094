// @ts-nocheck
import PropTypes from 'prop-types';
import {hasEveryOf, hasSomeOf} from '../../utils/user/user-utils';
import {useSataAuth} from "sata-auth";
import Unauthorized from "../errors/Unauthorized";
import RTKQueryFeedback from "../common/RTKQueryFeedback";
import {useGetCurrentUserQuery} from "../../store/apis/ccs";

export const AuthorizationGuard = ({children, userHasSomeOf, userHasEveryOf, redirectToUnauthorized, showErrorView}) => {
  const {account} = useSataAuth();
  const {data: user, error, isError, isLoading} = useGetCurrentUserQuery();

  // if (isLoading || isError) {
  //   return (
  //     <>
  //       {isError && showErrorView && <Unauthorized/>}
  //       <RTKQueryFeedback isLoading={isLoading} error={error} />
  //     </>
  //   );
  // }

  // if (!(account && hasSomeOf(account, userHasSomeOf) && hasEveryOf(account, userHasEveryOf)) && !(user && hasSomeOf(user, userHasSomeOf) && hasEveryOf(user, userHasEveryOf))) {
  //   if (redirectToUnauthorized) window.location.href = '/401';
  //   return null;
  // }

  return children;
};

AuthorizationGuard.propTypes = {
  userHasSomeOf: PropTypes.arrayOf(PropTypes.string),
  userHasEveryOf: PropTypes.arrayOf(PropTypes.string),
  redirectToUnauthorized: PropTypes.bool,
  showErrorView: PropTypes.bool
};

AuthorizationGuard.defaultProps = {
  userHasSomeOf: [],
  userHasEveryOf: [],
  redirectToUnauthorized: true,
  showErrorView: true,
};

export default AuthorizationGuard;
