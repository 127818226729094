// @ts-nocheck
import React from 'react';
import DeleteRefundSubjectDialog from './DeleteRefundSubjectDialog';
import {useFormContext, useWatch} from 'react-hook-form';
import {calculateRefundSubjects} from '../common/helpers';
import formState, {setFormData, setRefundSubjectToDelete} from '../../../../common/store/form-state';
import {useSnapshot} from 'valtio';

const DeleteRefundSubjectDialogConnected = () => {
  const {refundSubjectToDelete} = useSnapshot(formState);
  const {setValue} = useFormContext();
  const refundSubjects = useWatch({name: 'refundSubjects'});

  if (!refundSubjectToDelete) return null;

  /** @param {RefundSubject} rs */
  const deleteHandler = rs => {
    const updatedRefund = calculateRefundSubjects(refundSubjects.filter(r => r.documentNumber !== rs.documentNumber));
    setValue('refundSubjects', updatedRefund.refundSubjects, {shouldValidate: true});
    setValue('modified', updatedRefund.modified, {shouldValidate: true});
    setValue('totalRefund', updatedRefund.totalRefund, {shouldValidate: true});
    setFormData(updatedRefund);
  }

  return (
    <DeleteRefundSubjectDialog
      onDelete={() => deleteHandler(refundSubjectToDelete)}
      onClose={() => setRefundSubjectToDelete(null)}
    />
  );
};

export default DeleteRefundSubjectDialogConnected;
