import * as React from 'react';
import {MenuItem, Select} from '@mui/material';
import LabeledField from '../labeled-field/LabeledField';

interface RowsPerPageInterface {
    value: number;
    totalRowsCount: number;
    onChange: (number) => void;
    sx?: object;
}

export const RowsPerPageSelector = ({value, totalRowsCount, onChange, sx }: RowsPerPageInterface) => {
  // @ts-ignore
  const options = [...new Set([5, 10, 25, 50, 100, (totalRowsCount <= 100 ? totalRowsCount : NaN)])].filter((e) =>  e <= totalRowsCount);

  return (
    <LabeledField sx={undefined}>
      <Select size={'small'} sx={{width: '5rem', ...sx}} value={totalRowsCount >= 5 ? options.includes(value) ? value : totalRowsCount : totalRowsCount} onChange={(e) => onChange(e.target.value)}>
          {options.map((r, idx) => (
              <MenuItem key={idx} value={r}>{r}</MenuItem>
          ))}
      </Select>
    </LabeledField>
  );
};
export default RowsPerPageSelector;
