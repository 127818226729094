// @ts-nocheck
import React from 'react';
import { Button } from 'sata-mui-react-components';
import { Add, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { t } from 'ttag';
import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useCreateRefundMutation } from '../../../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import { useSnapshot } from 'valtio';
import formState, {
  decStep,
  incStep,
  resetToDefaultState,
  setFormData
} from '../store/form-state';

const FormStepperActions = () => {
  const history = useHistory();
  const { step } = useSnapshot(formState);
  const [createRefund, { data, error, isLoading }] = useCreateRefundMutation();
  const { handleSubmit } = useFormContext();
  const isLastStep = step === 2;

  const localHandleSubmit = (values) => {
    if (!isLastStep) {
      // Populate recipient
      if (step === 0) {
        setFormData(values);
        incStep();
      }
      if (step === 1) {
        setFormData(values);
        incStep();
      }

    } else {
      createRefund({ ...values, officeCode: values.office?.officeCode }).then(
        (r) => {
          if (!r.error) {
            resetToDefaultState();
            history.push(`/refunds/${r.data.refundId}`);
          }
        }
      );
    }
  };

  const prevHandler = () => decStep();
  const nextHandler = () => handleSubmit(localHandleSubmit)();

  return (
    <Box sx={{ textAlign: 'right' }}>
      {step > 0 && (
        <Button
          onClick={prevHandler}
          variant={'outlined'}
          startIcon={<KeyboardArrowLeft />}
        >
          {t`Back`}
        </Button>
      )}
      &nbsp;
      <Button
        onClick={nextHandler}
        endIcon={isLastStep ? <Add /> : <KeyboardArrowRight />}
      >
        {isLastStep ? t`Create` : t`Next`}
      </Button>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} />
    </Box>
  );
};

export default FormStepperActions;
