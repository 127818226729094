// @ts-nocheck
import { t } from 'ttag';
import React, { useState } from 'react';
import { Add } from '@material-ui/icons';
import {CardHeader, Typography} from '@material-ui/core';
import { Button, SimpleCard } from 'sata-mui-react-components';

import { RolesList } from './index';
import { AddRoleDialog, DeleteRoleDialog, EditRoleDialog } from './role-forms';

const Roles = () => {
  const [roleToAdd, setRoleToAdd] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(false);

  return (
    <>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Roles`}</Typography>}
          action={
            <Button
              onClick={() => setRoleToAdd({})}
              startIcon={<Add />}
            >{t`Add`}</Button>
          }
        />
        <RolesList
          editHandler={setRoleToEdit}
          deleteHandler={setRoleToDelete}
        />

        {roleToEdit && (
          <EditRoleDialog
            onClose={() => setRoleToEdit(false)}
            role={roleToEdit}
          />
        )}

        {roleToAdd && <AddRoleDialog role={roleToAdd} onClose={() => setRoleToAdd(false)} />}

        {roleToDelete && (
          <DeleteRoleDialog
            onClose={() => setRoleToDelete(false)}
            role={roleToDelete}
          />
        )}
      </SimpleCard>
    </>
  );
};

Roles.propTypes = {};

Roles.defaultProps = {};

export default Roles;
