import {Alert, Box, Button, CardContent, CardHeader, Collapse, Divider, Paper, Typography} from '@mui/material';
import {t} from 'ttag';
import {Theme} from '@mui/system';
import {useHistory} from 'react-router';
import {SaveOutlined} from '@mui/icons-material';
import React, {useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {BreadCrumbs, Page} from 'sata-mui-react-components';
// prettier-ignore
import {
  useGetCompaniesQuery,
  useGetTransportFacilitiesMotivesQuery,
  useGetTransportFacilitiesPerEmployeeMutation,
  usePutTransportFacilityRequestPerEmployeeMutation
} from '../../api';
import {transportFacilityValidationSchema} from './transport-facilities-form/schemas/transportFacilityValidationSchema';
import {createDatetime as dt, DATETIME_FORMAT, nowTz} from '../../../utils/datetime/datetime-utils';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {Employee} from '../../api/userEndpoints.types';
import {useMediaQuery} from '@material-ui/core';
import TransportFacilityForm from './transport-facilities-form/TransportFacilityForm';
import palette from '../../../theme/palette';
import {useGetCurrentUserQuery} from "../../../store/apis/ccs";

const breadcrumbs = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Industry Discount',
    link: '/transport-facilities'
  },
  {
    name: 'New Request'
  }
];

const CreateTransportFacility = () => {
  const navigate = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  // prettier-ignore
  const {data: user, isLoading: userLoading, error: userError} = useGetCurrentUserQuery({});
  // prettier-ignore
  const { data: companies, isLoading: companiesLoading, error: companiesError } = useGetCompaniesQuery({page: 0, rows: 100});
  // prettier-ignore
  const { data: motives, isLoading: motivesLoading, error: motivesError } = useGetTransportFacilitiesMotivesQuery({page: 0, rows: 100});
  // prettier-ignore
  const [onPutRequest, {error: putRequestError, isLoading: putRequestIsLoading, isSuccess: putRequestSuccess}] = usePutTransportFacilityRequestPerEmployeeMutation();
  // prettier-ignore
  const methods = useForm({ resolver: yupResolver(transportFacilityValidationSchema), defaultValues: { colaborator: user as unknown as Employee, travelDate: dt(nowTz(undefined, DATETIME_FORMAT)).plus({days: 1}).toFormat(DATETIME_FORMAT), travelCompany: '', isColdVacation: false, authorizations: [] as any, motive: '', isNonProfit: false, observations: '' }});
  // prettier-ignore
  const [getBeneficiary, {data: beneficiary, isLoading: beneficiaryLoading, error: beneficiaryError }] = useGetTransportFacilitiesPerEmployeeMutation();

  const travelCompany = methods.watch('travelCompany');
  const travelDate = methods.watch('travelDate').slice(0, 10);
  const includeIsColdVacation = methods.watch('isColdVacation');
  const colaborator = methods.watch('colaborator')?.employeeId;

  useMemo(() => {
    if (user && companies) {
      getBeneficiary({
        page: 0,
        rows: 100,
        travelInCompanyId: travelCompany || companies.content[0].id,
        travelDate: travelDate,
        includeIsColdVacation: includeIsColdVacation,
        collaboratorSelectedFacilityTypeId: selectedFacilityId || null,
        // @ts-ignore
        employeeId: colaborator || (sessionStorage.getItem('selectedEmployeeId') && JSON.parse(sessionStorage.getItem('selectedEmployeeId') || '{}'))?.employeeId || user?.employeeId
      });
    }
  }, [getBeneficiary, travelCompany, travelDate, includeIsColdVacation, colaborator, companies, user, selectedFacilityId])

  useEffect(() => {
    if (beneficiary && companies && user) {
      methods.reset({
        colaborator: methods.watch('colaborator') || user,
        travelDate:
          methods.watch('travelDate') ||
          dt(nowTz(undefined, DATETIME_FORMAT)).plus({days: 1}).toFormat(DATETIME_FORMAT),
        travelCompany: methods.watch('travelCompany') || companies.content[0].id,
        isColdVacation: methods.watch('isColdVacation') || false,
        motive: methods.watch('motive') || '',
        isNonProfit: methods.watch('isNonProfit') || false,
        observations: methods.watch('observations') || '',
        authorizations:  beneficiary.transportFacilitiesPerBeneficiaries.map((b) => {
          return {...b, selectedFacilityId: b.beneficiaryFamilyMember?.familyMember.relationshipType === 'COLABORADOR' ? selectedFacilityId : ''}
        }).map((b) => {
          return {...b, new: b.beneficiaryFamilyMember?.familyMember.relationshipType === 'ACOMPANHANTE' ? false : undefined}
        })
      });
    }
  },[beneficiary, methods, companies, user, selectedFacilityId]);

  const handleSubmit = methods.handleSubmit((values) => {
    const authorizations = values.authorizations?.filter((a) => a.selectedFacilityId)?.map((a) => (
      {
        facilityType: {id: a.selectedFacilityId},
        beneficiary: a.beneficiaryFamilyMember?.beneficiary?.id ? {id: a.beneficiaryFamilyMember?.beneficiary?.id} : null,
        familyMember: a.beneficiaryFamilyMember?.familyMember,
        reservationMethod: {id: a.allowanceDefinitionUsage?.find((b) => b?.facilityType?.id === a.selectedFacilityId)?.reservationMethod?.id},
      }));
    if(authorizations.length === 0){
      methods.setError('authorizations', {type: 'custom', message: t`É necessário selecionar pelo menos um tipo de facilidade de transporte para um beneficiário`})
    }else{
      if(authorizations.find((a) => (a.familyMember.name === null || a.familyMember.idNumber === null))){
          methods.setError('authorizations', {type: 'custom', message: t`Preencha os campos necessários para guardar o acompanhante`})
      }else{
        onPutRequest({
          employeeId: values.colaborator?.employeeId,
          travelDate: values.travelDate.slice(0, 10),
          observations: values.observations,
          travelCompany: {id: values.travelCompany},
          motive: {id: values.motive},
          isNonProfit: true,
          authorizations: authorizations
        }).then(// @ts-ignore
          (r) => !r.error && navigate.push('/transport-facilities'));
      }
    }
  });

  if (companiesLoading || motivesLoading || userLoading) {
    if (companiesError || motivesError || userError) {
      navigate.push('/transport-facilities');
    }
    return <RTKQueryFeedback isLoading={true} error={companiesError || motivesError || userError} />;
  }

  return (
    <Page breadcrumbs={<BreadCrumbs list={breadcrumbs} />}>
      <Paper elevation={3}>
        <Box position={'relative'}>
          <RTKQueryFeedback
            error={beneficiaryError || putRequestError}
            isLoading={beneficiaryLoading || putRequestIsLoading}
            isSuccess={putRequestSuccess}
            successMessage={t`A facilidade de transporte foi criada com sucesso`}
          />
          <FormProvider {...methods}>
            <Paper elevation={3}>
              <CardHeader
                sx={{display: isMobile ? 'block' : 'flex'}}
                title={
                  <Typography
                    fontSize={'1.375rem'}
                    sx={{fontFamily: 'NeoSansProMedium', color: palette.secondary.main}}
                  >{t`New Request`}</Typography>
                }
              />
              <Divider />
              <Collapse in={!beneficiary?.hasTransportFacilitiesEligibility && !beneficiaryLoading}>
                <Alert severity={'error'}>{t`Illegible to use Discount type in the selected company.`}</Alert>
              </Collapse>
              <CardContent>
                <TransportFacilityForm
                  companies={companies?.content}
                  motives={motives?.content}
                  onSetSelectedFacilityId={(value) => setSelectedFacilityId(value)}
                />
              </CardContent>
              <Divider />
              <Box display='flex' pr={3} py={2} gap={1} justifyContent={'flex-end'}>
                <Button
                  startIcon={<SaveOutlined />}
                  variant={'contained'}
                  onClick={handleSubmit}
                  disabled={!beneficiary?.hasTransportFacilitiesEligibility}
                >{t`Save`}</Button>
                <Button
                  color={'inherit'}
                  variant={'outlined'}
                  onClick={() => navigate.push('/transport-facilities')}
                >{t`Cancel`}</Button>
              </Box>
            </Paper>
          </FormProvider>
        </Box>
      </Paper>
    </Page>
  );
};

export default CreateTransportFacility;
