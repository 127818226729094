// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@material-ui/icons';
import {CardHeader, Typography} from '@material-ui/core';
import {Button, SimpleCard} from 'sata-mui-react-components';
import CreateOfficeFormDialog from './office-forms/CreateOfficeFormDialog';
import EditOfficeFormDialog from './office-forms/EditOfficeFormDialog';
import DeleteOfficeDialog from './office-forms/DeleteOfficeDialog';
import OfficesList from './OfficesList';

const Offices = () => {

  const [officeToCreate, setOfficeToCreate] = useState(null);
  const [officeToEdit, setOfficeToEdit] = useState(null);
  const [officeToDelete, setOfficeToDelete] = useState(null);


  return (
    <SimpleCard>
      <CardHeader
        title={<Typography variant={'h2'}>{t`Offices`}</Typography>}
        action={<Button onClick={() => setOfficeToCreate({active: true})} startIcon={<Add/>}>{t`NEW`}</Button>}
      />
      <OfficesList
        editHandler={setOfficeToEdit}
        removeHandler={setOfficeToDelete}
      />
      {officeToCreate && (
        <CreateOfficeFormDialog
          office={officeToCreate}
          onClose={() => setOfficeToCreate(null)} open={undefined}        />
      )}
      {officeToEdit && (
        <EditOfficeFormDialog
          office={officeToEdit}
          onClose={() => setOfficeToEdit(null)} open={undefined}        />
      )}
      {officeToDelete && (
        <DeleteOfficeDialog
          office={officeToDelete}
          onClose={() => setOfficeToDelete(null)} open={undefined}        />
      )}
    </SimpleCard>
  );
};

export default Offices;
