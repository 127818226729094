// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {CloudDownload} from '@material-ui/icons';
import {Button, SimpleCard} from 'sata-mui-react-components';

import {EmployeesList} from './index';
import {DeleteEmployeeDialog, EditEmployeeDialog, ImportEmployeeDialog} from './employee-forms';

const Employees = () => {
  const [employeeToEdit, setEmployeeToEdit] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(false);
  const [employeeToImport, setEmployeeToImport] = useState(false);

  return (
    <>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Employees`}</Typography>}
          action={
            <Button
              onClick={() => setEmployeeToImport(true)}
              startIcon={<CloudDownload />}
            >{t`IMPORT`}</Button>
          }
        />

        <EmployeesList
          editHandler={setEmployeeToEdit}
          removeHandler={setEmployeeToDelete}
        />

        {employeeToEdit && (
          <EditEmployeeDialog
            onClose={() => setEmployeeToEdit(false)}
            employee={employeeToEdit}
          />
        )}

        {employeeToImport && (
          <ImportEmployeeDialog onClose={() => setEmployeeToImport(false)} />
        )}

        {employeeToDelete && (
          <DeleteEmployeeDialog
            onClose={() => setEmployeeToDelete(false)}
            employee={employeeToDelete}
          />
        )}
      </SimpleCard>
    </>
  );
};

Employees.propTypes = {};

Employees.defaultProps = {};

export default Employees;
