// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {AlertDialogModal, Button} from 'sata-mui-react-components';
import {useDeleteIssueOfficeMutation} from '../../../../store/apis/ccs';
import {Box, DialogActions, DialogContent} from '@material-ui/core';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';

const DeleteIssueOfficeDialog = ({issueOffice, open, onClose, ...rest}) => {

  const [deleteIssueOffice, {data, error, isLoading, isSuccess}] = useDeleteIssueOfficeMutation();

  return (
    <AlertDialogModal title={t`Issue office`} variant={'error'} open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading} isSuccess={isSuccess}/>
      <DialogContent dividers>
        <Box p={1}>
          {t`Do you really want to delete this item?`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mx={1}>
          <Button variant={'contained'} type={'error'} onClick={() => deleteIssueOffice(issueOffice).then(r => !r.error && onClose())}>{t`Delete`}</Button>
        </Box>
        <Box mx={1}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
      </DialogActions>
    </AlertDialogModal>
  );
};

DeleteIssueOfficeDialog.propTypes = {
  issueOffice: PropTypes.shape({
    issueOfficeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteIssueOfficeDialog.defaultProps = {
  issueOffice: null,
  open: true,
  onClose: null,
};

export default DeleteIssueOfficeDialog;
