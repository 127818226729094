import {t} from "ttag";
import React from 'react';
import {Check} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useRefundAuthorizationMutation} from "../../../../api";
import {IAuthorization} from "../../../../api/transportFacilityEndpoints.types";
import RTKQueryFeedback from "../../../../../components/common/RTKQueryFeedback";

export const TransportFacilitiesRefund = ({data, onClose, fullScreen}) => {
  const [onRefund, {data: dataRefund, error, isLoading}] = useRefundAuthorizationMutation();

  const handleSave = () => {
    onRefund(data).then((e: { data?: IAuthorization; error?: any;}) => !e?.error && onClose())
  };

  return (
    <>
    <Dialog fullScreen={fullScreen} onClose={onClose} open>
      <DialogTitle id='responsive-dialog-title'>{'Cancel'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{t`Do you really want to refund this Transport Facility?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'inherit'} onClick={onClose} autoFocus>
          {t`Cancel`}
        </Button>
        <Button
          startIcon={<Check />}
          onClick={handleSave}
          disabled={isLoading}
          variant={'contained'}
          color={'primary'}
          autoFocus
        >{t`Comfirm`}</Button>
      </DialogActions>
    </Dialog>
    <RTKQueryFeedback data={dataRefund} error={error} isLoading={isLoading} successMessage={t`Transport Facility refunded with success!`} />
    </>
  );
};

export default TransportFacilitiesRefund;
