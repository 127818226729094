import React, { useEffect } from "react";
import { useSataAuth } from "sata-auth";
import { LoadingScreen } from "sata-mui-react-components";
import {Box} from "@mui/material";

const Auth = ({ children, history, match }) => {
  const { isAuthenticated, isInitialized } = useSataAuth();

  useEffect(() => {
    if (isInitialized && !isAuthenticated && !['/auth', '/logout', '/401', '/404', '/403'].includes(match.url)) {
      window.sessionStorage.setItem('_redirect_', match.url);
      history.push('/auth');
    }
  }, [isAuthenticated, isInitialized, history, match]);

  return isInitialized && isAuthenticated
    ? <div className="auth-guard">
      {children}
    </div>
    : <Box mt={3}><LoadingScreen /></Box>;
};

export default Auth;
