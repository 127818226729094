// @ts-nocheck
import React, {useMemo} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {useWatch} from 'react-hook-form';
import Amount from './Amount';

const RefundSubjectInfo = () => {

  const [reservationCode, companyCode, documentNumber, amounts] = useWatch({name: ['reservationCode', 'companyCode', 'documentNumber', 'amounts']});

  const totalFareRefund = useMemo(() => amounts?.find(a => a.type === 'totalFareRefund') || {}, [amounts]);
  const totalTaxes = useMemo(() => amounts?.find(a => a.type === 'totalPaidTaxes') || {}, [amounts]);
  const totalAmount = useMemo(() => amounts?.find(a => a.type === 'totalAmount') || {}, [amounts]);

  const field = s => <Typography noWrap variant={'caption'} component={'div'}>{s}</Typography>;

  return (
    <Card>
      <CardHeader title={t`Basic info`}/>
      <CardContent>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{t`PNR`}</TableCell>
                <TableCell align={'right'}>{t`Ticket`}</TableCell>
                <TableCell align={'right'}>{t`Fare`}</TableCell>
                <TableCell align={'right'}>{t`Total taxes`}</TableCell>
                <TableCell align={'right'}>{t`Total amount`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={'th'} scope={'row'}>{field(reservationCode)}</TableCell>
                <TableCell align={'right'}>{field(`${companyCode}${documentNumber}`)}</TableCell>
                <TableCell align={'right'}>{field(<Amount {...totalFareRefund}/>)}</TableCell>
                <TableCell align={'right'}>{field(<Amount {...totalTaxes}/>)}</TableCell>
                <TableCell align={'right'}>{field(<Amount {...totalAmount}/>)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

RefundSubjectInfo.propTypes = {
  formik: PropTypes.shape({}),
};

export default RefundSubjectInfo;
