import PropTypes from 'prop-types';

const Amount = ({currency, value, defaultValue}) => {
  let formattedAmount = defaultValue;

  if (value || typeof value === 'number') {
    formattedAmount = parseFloat(value).toFixed(2) || '';
  }

  if (currency) {
    formattedAmount = `${currency} ${formattedAmount}`;
  }

  return formattedAmount;
};

Amount.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
};

Amount.defaultProps = {
  children: {
    value: 0,
    currency: ''
  },
  defaultValue: '-'
};

export default Amount;
