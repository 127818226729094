import PropTypes from 'prop-types';
import {Avatar} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {axiosInstance} from "../../../utils";

const ProfilePicture = ({name, height, width}) => {

  const [photo, setPhoto] = useState('');
  useEffect(() => {
    axiosInstance
        .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          responseType: 'blob'
        })
        .then((data) => {
          if (data?.status === 200) {
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data.data);
            setPhoto(blobUrl);
          }
        });
  }, []);

  const initials = (fullName) =>
  {
    let arrName = fullName.split(" ");
    return fullName.charAt(0) + arrName[arrName.length - 1].charAt(0);
  }


  return photo ? (
    <Avatar sx={{height, width}} src={photo} />
  ) : (
    <Avatar sx={{height, width}}>
      {initials(name || '-')}
    </Avatar>
  );
};

ProfilePicture.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

ProfilePicture.defaultProps = {
  height: '48px',
  width: '48px'
};

export default ProfilePicture;
