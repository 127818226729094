// @ts-nocheck
export const type = 'issueOffice';
export const endpoints = (builder) => ({
  // Get issueOffices list
  getIssueOffices: builder.query({
    query: () => ({ url: `/issue-offices`, destructFn: (d) => d?.data?.data }),
    providesTags: ( data = [] ) => {
      return data
        .map(({ issueOfficeId }) => ({ type, id: issueOfficeId }))
        .concat({ type, id: 'list' });
    }
  }),

  // Create issueOffice
  createIssueOffice: builder.mutation({
    query: (issueOffice) => ({
      url: '/issue-offices',
      method: 'POST',
      body: issueOffice
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  // Edit issueOffice
  editIssueOffice: builder.mutation({
    query: (issueOffice) => ({
      url: `/issue-offices/${issueOffice.issueOfficeId}`,
      method: 'PATCH',
      body: issueOffice
    }),
    invalidatesTags: (result, error, { issueOfficeId }) => {
      return [{ type, id: issueOfficeId }];
    }
  }),

  // Delete issueOffice
  deleteIssueOffice: builder.mutation({
    query: (issueOffice) => ({
      url: `/issue-offices/${issueOffice.issueOfficeId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  })
});

export default endpoints;
