import React from 'react';
import {BreadCrumbs, Page} from 'sata-mui-react-components';
import {Employees, FormsOfPayment, Offices, Roles} from './index';
import {useHistory, useRouteMatch} from 'react-router';
import {Tab, Tabs} from '@material-ui/core';
import {t} from 'ttag';
import {Route, Switch} from 'react-router-dom';
import {Box} from "@mui/material";

const Settings = () => {

  const {path, url} = useRouteMatch();
  const history = useHistory();

  const a11yProps = (idx) => ({
    id: `simple-tab-${idx}`,
    'aria-controls': `simple-tabpanel-${idx}`
  });

  return (
    <Page breadcrumbs={<BreadCrumbs list={[{name: 'Home', link: '/'}, {name: 'Settings'}]}/>}>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={history.location.pathname}
            onChange={(_, tab) => history.push(tab)}
            aria-label='Settings menu'
            variant={'scrollable'}
            scrollButtons={'auto'}
          >
            <Tab label={t`Employees`} value={`${path}/employees`} {...a11yProps(0)} />
            <Tab label={t`Roles`} value={`${path}/roles`} {...a11yProps(1)} />
            <Tab label={t`Offices`} value={`${path}/offices`} {...a11yProps(2)} />
            <Tab label={t`Form of Payments`} value={`${path}/forms-of-payment`} {...a11yProps(3)} />
            {/*<Tab label={t`Issue Offices`} value={`${path}/issue-offices`} {...a11yProps(4)} />*/}
          </Tabs>
        </Box>
        <Box sx={{p: 3}}>
          <Switch>
            <Route exact path={`${url}/employees`}>
              <Employees/>
            </Route>
            <Route exact path={`${url}/roles`}>
              <Roles/>
            </Route>
            <Route exact path={`${url}/offices`}>
              <Offices/>
            </Route>
            <Route exact path={`${url}/forms-of-payment`}>
              <FormsOfPayment/>
            </Route>
            {/*<Route exact path={`${url}/issue-offices`}>
              <IssueOffices/>
            </Route>*/}
          </Switch>
        </Box>
      </Box>
    </Page>
  );
};

export default Settings;
