import React from 'react';
import formState, {setAtcMaskVisible} from '../../../../../../../common/store/form-state';
import AtcMaskToggle from './AtcMaskToggle';
import {useSnapshot} from 'valtio';

const AtcMaskToggleConnected = () => {
  const {atcMaskVisible} = useSnapshot(formState);

  return <AtcMaskToggle visible={atcMaskVisible} onChange={setAtcMaskVisible}/>;
};

export default AtcMaskToggleConnected;
