import {t} from 'ttag';
import React, {useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import RTKQueryFeedback from "../../../components/common/RTKQueryFeedback";
import {useGetUsersQuery} from "../../api";

export const EmployeeSelector = ({ value, onChange, includesDisabled = false }) => {
    const [pattern, setPattern] = useState(value?.name || '');
    const {data = [], error, isLoading} = useGetUsersQuery({searchPattern: pattern || '1000', includesDisabled});
    const [input, setInput] = useState('1000');

    const searchHandler = useDebouncedCallback((inputPattern) => {
        if (inputPattern.length >= 3) {
            setPattern(inputPattern.substring(0, inputPattern.indexOf(' (')).length >= 3 ? inputPattern.substring(0, inputPattern.indexOf(' (')) : inputPattern)
        }
    }, 300);

    const handleInputChange = (_, pattern) => {
        setInput(pattern);
        searchHandler(pattern);
    };

    return (
        <>
            <RTKQueryFeedback error={error}/>
            <Autocomplete
                noOptionsText={t`No options`}
                loadingText={t`Loading...`}
                loading={isLoading}
                disableClearable
                options={data}
                inputValue={input}
                renderTags={() => null}
                value={value}
                size={'small'}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                getOptionLabel={(opt) => opt && `${opt.name} (${opt.employeeId})`}
                filterOptions={(opts, {inputValue = ''}) => {
                    return opts.filter(o => `${o.employeeId} ${o.name}`.toLowerCase().includes(inputValue.toLowerCase()));
                }}
                onInputChange={handleInputChange}
                onChange={(_, user) => onChange(data.find((u) => u.employeeId === user.employeeId))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size={'small'}
                        placeholder={t`Start typing...`}
                        onKeyDown={e => e.key === 'Backspace' && e.stopPropagation()}
                        variant={'outlined'}
                        sx={{backgroundColor:'white'}}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color='inherit' size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                          sx: {padding: '0px !important'}
                        }}
                    />
                )}
            />
        </>
    );
}

export default EmployeeSelector;
