import {createStore, resetStore} from './form-state-utils';

export * from './form-state-utils';

export const defaultState = {
  step: 0,
  refundSubjectToImport: null,
  refundSubjectToEdit: null,
  refundSubjectToDelete: null,
  atcMaskVisible: false,
  form: {
    refundType: '',
    office: null,
    reason: '',
    refundSubjects: [],
    totalRefund: 0,
    modified: null,
    recipient: {},
  }
};

export const formState = createStore(defaultState);

// Actions
export const setStep = step => formState.step = step;
export const setFormData = data => formState.form = {...formState.form, ...data};
export const incStep = () => ++formState.step;
export const decStep = () => --formState.step;
export const setRefundSubjectToImport = rs => formState.refundSubjectToImport = rs;
export const setRefundSubjectToEdit = rs => formState.refundSubjectToEdit = rs;
export const setRefundSubjectToDelete = rs => formState.refundSubjectToDelete = rs;
export const setAtcMaskVisible = visible => formState.atcMaskVisible = visible;
export const resetToDefaultState = () => resetStore(formState, defaultState);

export default formState;
