// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { AlertDialogModal, Button } from 'sata-mui-react-components';

import { useDeleteUserMutation } from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const DeleteEmployeeDialog = ({ open, onClose, employee }) => {
  const [deleteUser, { data, error, isLoading, isSuccess }] =
    useDeleteUserMutation();

  const handleSave = () => {
    deleteUser(employee).then((r) => !r.error && onClose());
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        successMessage={t`The employee ${employee.name} was successfully deleted!`}
      />
      <AlertDialogModal
        variant='error'
        title={t`Delete Employee`}
        open={open}
        onClose={onClose}
      >
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to delete this item?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button variant='contained' type='error' onClick={handleSave}>
              {t`Delete`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
        </DialogActions>
      </AlertDialogModal>
    </>
  );
};

DeleteEmployeeDialog.propTypes = {
  employee: PropTypes.shape({
    employeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DeleteEmployeeDialog.defaultProps = {
  open: true
};

export default DeleteEmployeeDialog;
