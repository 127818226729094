export const MSA_AUTHORITY = process.env.REACT_APP_MSA_AUTHORITY;
export const MSA_CLIENT_ID = process.env.REACT_APP_MSA_CLIENT_ID;

export const MSA_SCOPES_EMPLOYEE =   process.env.REACT_APP_MSA_EMPLOYEE_PORTAL_SCOPES;
export const AUDIENCE_EMPLOYEE_PORTAL =   process.env.REACT_APP_EMPLOYEE_PORTAL_AUDIENCE;
// CCS - Contact Center Shell
export const AUDIENCE_CCS = process.env.REACT_APP_AUDIENCE_CCS;
export const MSA_SCOPES_CCS = process.env.REACT_APP_MSA_SCOPES_CCS;
export const API_ENDPOINT_CCS = process.env.REACT_APP_API_ENDPOINT_CCS;

// TRANSPORT - Transport Facilities
export const AUDIENCE_TRANSPORT = process.env.REACT_APP_AUDIENCE_TRANSPORT;
export const MSA_SCOPES_TRANSPORT = process.env.REACT_APP_MSA_SCOPES_TRANSPORT;
export const API_ENDPOINT_TRANSPORT = process.env.REACT_APP_API_ENDPOINT_TRANSPORT;

export const ENABLE_REDUX_DEV_TOOLS = process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_CCS;
export const API_MOCK = process.env.REACT_APP_API_MOCK === 'true';
