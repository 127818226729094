import {t} from 'ttag';
import {Delete} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';

import Amount from '../Amount';
import {Box} from "@mui/material";

export const columns = ({
  removeHandler,
  editable,
  disableDeleteBtn = false
}) => [
  {
    field: 'taxCode',
    headerName: t`Code`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'value',
    type: 'number',
    headerName: t`Value`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      <Amount value={row.value} currency={row.currency} />
    )
  },
  {
    field: 'currentValue',
    type: 'number',
    headerName: t`Current value`,
    editable: editable,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => (
      <Amount value={row.currentValue} currency={row.currency} />
    )
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    hide: !editable,
    type: 'number',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box>
          <IconButton
            onClick={() => removeHandler(params.row)}
            disabled={disableDeleteBtn}
            size={'small'}
            aria-label='delete'
          >
            <Delete fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];
