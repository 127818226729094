const DISPLAY_TKT = 'TRF%s-%s';
const CANCELLATION_FEE = '/CP%sA';
const TRFP = 'TRFP';
const TRFU = 'TRFU%s';
const ADD_TAX = '/TA%s%s';
const FARE_USED = '/U%s';
const REMOVE_TAX = '/TX1-%s';
const ADD_RM = 'TRFU/RM REFUND PROCESS %s';
const REMOVE_PAYMENT = 'TRFU/FP%s';
const ADD_PAYMENTS = 'TRFU/FPa%s%s';
const ADD_PAYMENTS_TYPES = 'TRFU/FP%s%s';

// const VOLUNTARY = 'VOLUNTARY';
const INVOLUNTARY = 'INVOLUNTARY';

/**
 * @param {Refund} refund
 * @param {FormOfPayment[]} formsOfPayment
 * @return {string[]}
 */
export const genCommands = (refund, formsOfPayment ) => {
  return refund.refundSubjects.reduce((commands, rs) => {
    let documentNumber = rs.documentNumber.concat(rs.type === 'SERVICE' ? '/EMD': '');
    documentNumber = documentNumber.concat(rs.type === 'SERVICE' && refund.type === INVOLUNTARY ? '/INVOL': '');
    return commands
      .concat([sf(DISPLAY_TKT, rs.companyCode, documentNumber)])
      .concat(genTaxesCommands(refund, rs))
      .concat(genPaymentOptionCommands(refund, rs, formsOfPayment))
      .concat([sf(ADD_RM, refund.processId || '##########')])
      .concat([TRFP]);
  }, []);
};

/**
 * @param {Refund} refund
 * @param {RefundSubject} rs
 * @return {string[]}
 */
const genTaxesCommands = (refund, rs) => {
  // const taxType = refund.type === VOLUNTARY ? 'paidTaxes' : 'refundableTaxes'; //ver com o gerson
  const totalCancellationFee =
    rs.amounts.find((a) => a.type === 'totalCancellationFee')?.currentValue ||
    0;
  return [
    sf(
      TRFU,
      (totalCancellationFee
        ? sf(CANCELLATION_FEE, nf(totalCancellationFee))
        : ''
      )
        // .concat(sf(REMOVE_TAX, rs.taxes.filter(tx => tx.type === taxType).length + 1))
        .concat(rs.taxes.length !== 0 ? sf(REMOVE_TAX, rs.taxes.length) : '')
        .concat(
          rs.taxes
            .filter((tx) => tx.active && tx.type === 'refundableTaxes')
            .reduce(
              (a, tx) => a + sf(ADD_TAX, nf(tx.currentValue), tx.taxCode),
              ''
            )
        )
        .concat(
          sf(
            FARE_USED,
            nf(
              rs.amounts.find((a) => a.type === 'totalFareUsed')
                ?.currentValue || 0
            )
          )
        )
    )
  ];
};

/**
 * @param {Refund} refund
 * @param {RefundSubject} rs
 * @param {FormOfPayment[]} formsOfPayment
 * @return {string[]}
 */
const genPaymentOptionCommands = (refund, rs, formsOfPayment) => {
  return rs.paymentOptions.reduce((c, po, idx) => {
    return c
      .concat([sf(REMOVE_PAYMENT, 1)])
      .concat([sf(ADD_PAYMENTS_TYPES, idx + 1, formsOfPayment.find(fp => fp.code === po.indicator)?.amadeusCode || po.indicator)])
      .concat([sf(ADD_PAYMENTS, idx + 1, nf(po.currentValue))]);
  }, []);
};

/**
 * Format string ex. sf('%s %s', 'hello', 'world') results in 'hello world'
 * @param {string|number} str
 * @param {...string|number} args
 * @return {string}
 */
const sf = (str, ...args) => args.reduce((s, v) => s.replace('%s', v), str);

/**
 * Format number ex. 1505.00
 * @param {number|string} n
 * @return {string}
 */
const nf = (n) => String(parseFloat(n).toFixed(2));
