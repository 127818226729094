import {axiosInstance} from './auth-axios-instance';
import AxiosError from './AxiosError';

export const axiosBaseQuery = ({baseUrl}:{baseUrl: string|undefined;} = {baseUrl: ''}) => {
  return async ({url = '', method, body: data, ...rest}) => {
    try {
      return {data: await axiosInstance({url: url.includes('http') ? url : baseUrl + url, method, data, ...rest})};
    } catch (e: any) {
      if (e?.response?.status === 401) {
        // window.location.href = '/401';
      }
      throw new AxiosError(e?.message, e?.response?.status, JSON.stringify(e?.response?.data));
    }
  };
};
