// @ts-nocheck
import React, {useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import RefundSubjectFormDialogConnected
  from './refund-subjects/crud-forms/create-edit/RefundSubjectFormDialogConnected';
import DeleteRefundSubjectDialogConnected from './refund-subjects/crud-forms/delete/DeleteRefundSubjectDialogConnected';
import RefundSubjectsListConnected from './refund-subjects/list/RefundSubjectsListConnected';
import RefundSubjectImportFormConnected from './refund-subjects/import/RefundSubjectImportFormConnected';
import FormStepperActions from '../common/stepper/FormStepperActions';
import ErrorsFeedbackConnected from '../common/ErrorsFeedbackConnected';
import {useFormContext} from 'react-hook-form';

const Step2Form = () => {

  const {clearErrors} = useFormContext();

  useEffect(() => {
    clearErrors();
  }, [clearErrors])

  return (
    <Grid container>
      <Grid xs={12} md={12} item>
        <RefundSubjectImportFormConnected/>
      </Grid>
      <Grid xs={12} md={12} item>
        <RefundSubjectsListConnected/>
        <RefundSubjectFormDialogConnected/>
        <DeleteRefundSubjectDialogConnected/>
      </Grid>
      <Grid xs={12} md={12} item>
        <Box p={1}>
          <ErrorsFeedbackConnected/>
        </Box>
        <br/>
        <FormStepperActions/>
      </Grid>
    </Grid>
  );
};

export default Step2Form;
