import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, Grid, Switch, TextField} from '@material-ui/core';
import {t} from 'ttag';

const IssueOfficeForm = ({formik}) => {

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={8} item>
        <TextField
          label={t`Description`}
          name={'description'}
          color={'primary'}
          variant={'outlined'}
          onChange={e => formik.setFieldValue('description', e.target.value.toUpperCase())}
          value={formik.values?.description || ''}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description || ''}
          required
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <FormControlLabel
          label={t`Active`}
          color={'primary'}
          // error={Boolean(formik.errors.active)}
          // helperText={formik.errors.active || ''}
          control={(
            <Switch
              name={'active'}
              // onChange={(e, value) => formik.setFieldValue(value)}
              onChange={formik.handleChange}
              checked={Boolean(formik.values?.active)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

IssueOfficeForm.propTypes = {
  formik: PropTypes.shape({}),
};

IssueOfficeForm.defaultProps = {
  formik: {}
};

export default IssueOfficeForm;
