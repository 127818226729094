import {t} from "ttag";
import React from 'react';
import {Box, LinearProgress, linearProgressClasses, Typography} from '@mui/material';

export const AllowanceProgressBar = ({total, count}) => {
    const percentage = total >= count ? Math.round((count/total)*100) < 15 ? 15 : Math.round((count/total)*100) : 0;

    return (
        <Box display={'flex'} justifyContent={'flex-end'}>
            <Box display={'flex'} position={'relative'}>
                <Typography align={'right'} sx={{position: 'absolute', zIndex: 9999, color: 'white', width: ((isNaN(percentage) ? 100 : percentage) - 5) + '%'}} fontSize={'0.8rem'} fontFamily={'NeoSansProMedium'}>{count}</Typography>
                <LinearProgress sx={styles.progressBar} variant={"determinate"} value={percentage}/>
            </Box>
            <Typography sx={{ml: 1}} color={'accordion.label'} fontSize={'0.85rem'} fontWeight={300}>{total === 0 ? t`Ilimitado` : total}</Typography>
        </Box>
    );
};

const styles = {
    progressBar: {
        width: '100px',
        height: '1.1rem',
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#EDF1F5'
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: 'primary',
        },
    }
}

export default AllowanceProgressBar;