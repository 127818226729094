import {createMsalInstance} from 'sata-auth';
import {
  MSA_AUTHORITY,
  MSA_CLIENT_ID,
  MSA_SCOPES_CCS,
  MSA_SCOPES_EMPLOYEE,
  // MSA_SCOPES_TRANSPORT
} from './settings';

const settings = {
  auth: {
    clientId: MSA_CLIENT_ID,
    authority: MSA_AUTHORITY,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const scopes = [MSA_SCOPES_CCS, MSA_SCOPES_EMPLOYEE];

// @ts-ignore
export const msalInstance = createMsalInstance(settings);
