import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Search} from '@mui/icons-material';
import {Box, TextField} from '@mui/material';
import {useDebouncedCallback} from 'use-debounce';

export const PatternSearch = ({placeholder, onChange, ...rest}) => {
  const [patternInput, setPatternInput] = useState(rest.value || '');
  const handlePattern = useDebouncedCallback((value) => onChange(value), 750);

  return (
    <TextField
      {...rest}
      value={patternInput}
      sx={{width: '100%', '& .MuiOutlinedInput-root' :{
          paddingRight: '0px',
          }}}
      placeholder={placeholder}
      onChange={(e) => {
        setPatternInput(e.target.value);
        handlePattern(e.target.value);
      }}
      size={'small'}
      InputProps={{
        endAdornment: (
            <Box
                sx={{
                    borderLeft: '1px solid #00285733',
                    borderBottomRightRadius: '4px',
                    borderTopRightRadius: '4px',
                    backgroundColor: '#F9FAFC',
                    padding: '6.5px 14px'
                }}
            >
                <Search fontSize={'small'} />
            </Box>
        )
      }}
      fullWidth
    />
  );
};

PatternSearch.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

PatternSearch.defaultProps = {
  placeholder: t`Search`,
  onChange: () => null
};

export default PatternSearch;
